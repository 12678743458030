import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VoiceMenu from "../Common/VoiceMenu";

export default function Voices() {
    const [voices, setVoices] = useState(null);
    const [voiceName, setVoiceName] = useState("");
    const [voiceID, setVoiceUUID] = useState("new");
    const [wordTotal, setWordTotal] = useState(null);
    const [voiceDialogTitle, setVoiceDialogTitle] = useState(null);
    const [voiceDialogOpen, setVoiceDialogOpen] = useState(false);

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const updateVoiceName = (e) => {
        setVoiceName(e.target.value);
    };

    const handleVoiceDialogOpen = (id, name = "") => {
        if (id === "new") {
            setVoiceDialogTitle("Add New Voice");
        } else {
            setVoiceDialogTitle("Edit Voice Details");
        }
        setVoiceUUID(id);
        setVoiceName(name);
        setVoiceDialogOpen(true);
    };

    const handleClose = () => {
        setVoiceDialogOpen(false);
    };

    const deleteVoice = (id) => {
        Swal.fire({
            title: "Remove this Voice?",
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#CC0000",
            showClass: {
                popup: "",
            },
            hideClass: {
                popup: "",
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voices/" + id + "/delete", {
                        voice_uuid: id,
                    })
                    .then(() => {
                        getData();
                    })
                    .catch(() => {});
            }
        });
    };

    const saveBaseVoice = () => {
        let voice_uuid;
        voice_uuid = "new";
        if (voiceName !== "") {
            let objPayload = {
                voice_uuid: voice_uuid,
                voice_alias: voiceName,
                tts_platform: "Express",
                voice_gender: "n/a",
                language_id: "32",
                avatar: "Default",
            };

            console.log("SAVING");
            console.log(objPayload);
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voices/save", objPayload)
                .then((res) => {
                    console.log("voice saved... ", res);
                    window.location.href = "/voices";
                })
                .catch(() => {});
        } else {
            alert("Voice Alias is required.");
        }
    };

    async function getData() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voices/" + objUser.company_uuid + "/" + objUser.user_uuid);
        const data = await response.json();
        console.log(data);
        setVoices(data);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>Voices</li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="alignRight topMenuRight">
                            <span onClick={() => handleVoiceDialogOpen("new", "")} className="btn-sm btn btn-success addButton">
                                <i className="fa-solid fa-folder"></i> <div className="addButtonText">Add Voice</div>
                            </span>
                        </div>
                    </Grid>
                </Grid>

                <Box xs={12} className="mainPanel">
                    <Grid container spacing={2}>
                        {voices && (
                            <>
                                {voices.map((voice) => (
                                    <>
                                        <Paper className="projectBox">
                                            <Box xs={12} key={voice.id} className="projectBoxInfo">
                                                <Grid container spacing={0}>
                                                    <Grid item xs={9}>
                                                        <h4>{voice.voice_alias}</h4>
                                                        {voice.readable_label}, {voice.voice_gender}
                                                    </Grid>
                                                    <Grid item xs={3} style={{ position: "absolute", right: "10px" }}>
                                                        <VoiceMenu voice={voice} deleteVoice={deleteVoice} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider />
                                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: "background.default" }}>
                                                <a className="btn-sm btn btn-success addButton" href={"/voice/" + voice.voice_uuid + "/files"}>
                                                    <i className="fa-solid fa-file-audio"></i> <div className="addButtonText">Edit</div>
                                                </a>
                                            </Stack>
                                        </Paper>
                                    </>
                                ))}
                            </>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Dialog open={voiceDialogOpen} onClose={handleClose}>
                <DialogTitle>{voiceDialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Voice Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={updateVoiceName}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") saveBaseVoice();
                        }}
                        value={voiceName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="cancelButton" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={saveBaseVoice}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
