import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Grid, Paper, Typography, Box, Menu, MenuItem, Select, InputLabel } from "@mui/material";

const { v4: uuid_v4 } = require("uuid");

export default function Voice() {
    const { id } = useParams();
    const [voice, setVoice] = useState("");
    const history = useHistory();
    let voiceUUID = uuid_v4();
    let voiceAlias = useRef(null);
    let voiceName = useRef(null);
    const [file, setFile] = useState(null);
    const [languages, setLanguages] = useState();

    async function getData() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice/" + id);
        try {
            const data = await response.json();
            console.log(data);
            setVoice(data);
        } catch (error) {
            console.log("new voice");
            setVoice({
                ...voice,
                tts_platform: "Express",
                voice_alias: "",
                voice_gender: "Female",
                language_id: 32,
                avatar: "Default",
            });
        }
    }

    const handleLanguageChange = (event) => {
        console.log("set lang to ", event);
        setVoice({
            ...voice,
            language_id: event.target.value,
        });
    };

    const inputRef = useRef();

    const handleChangeFile = (event) => {
        console.log(event.target.files);
        //OR
        console.log(inputRef.current.files);
        saveVoice();
    };

    const saveVoice = () => {
        let voice_uuid;
        if (id == "new") {
            voice_uuid = "new";
        } else {
            voice_uuid = voice.voice_uuid;
        }
        console.log("voice_uuid=" + voice_uuid);
        if (voice.voice_alias !== "") {
            let objPayload = {
                voice_uuid: voice_uuid,
                voice_alias: voice.voice_alias,
                tts_platform: voice.tts_platform ?? "Express",
                voice_gender: voice.voice_gender,
                language_id: voice.language_id,
                avatar: voice.avatar,
            };

            console.log("SAVING");
            console.log(objPayload);
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voices/save", objPayload)
                .then((res) => {
                    console.log("voice saved... ", res);
                    history.push("/voices");
                })
                .catch(() => {});
        } else {
            alert("Voice Alias is required.");
        }
    };

    async function getLanguages(sequenceData = null) {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_languages");
        const data = await response.json();
        setLanguages(data);
    }

    const updateFiles = () => {
        let formData = new FormData();
        formData.append("voice_alias", voice.voice_alias);
        formData.append("id", voice.voice_uuid);
        for (let i = 0; i < inputRef.current.files.length; i++) {
            formData.append("files", inputRef.current.files[i]);
        }
        console.log(formData.get("files"));
        axios({
            method: "POST",
            url: process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice/save",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    };

    const handleAliasChange = (e) => {
        setVoice({
            ...voice,
            voice_alias: e.target.value,
        });
    };

    const handleGenderChange = (e) => {
        setVoice({
            ...voice,
            voice_gender: e.target.value,
        });
    };

    const handlePlatformChange = (e) => {
        setVoice({
            ...voice,
            voice_platform: e.target.value,
        });
    };

    useEffect(() => {
        getData();
        getLanguages();
    }, []);

    return (
        <div className="page wizardPage">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb"></ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered wizardPanel">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-12">
                            <h4>
                                <a href="/voices">Voices</a>: Edit Voice
                            </h4>
                            Enter the details of the voice set below.
                            <div className="formDivider"></div>
                            {voice && (
                                <>
                                    <div className="row formRow">
                                        <div className="col-sm-6">
                                            <label className="formLabel">Voice Alias (display name in UI)</label>
                                            <input type="text" value={voice.voice_alias} className="form-control" onChange={handleAliasChange} autoFocus />
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="formLabel">Language</label>
                                            {languages && (
                                                <>
                                                    <Select label="Langauge" name="lauguage_code" style={{ minWidth: "100%" }} onChange={handleLanguageChange} value={voice.language_id}>
                                                        {languages.map((language) => (
                                                            <MenuItem disableRipple value={language.id} key={language.id}>
                                                                <div className="avatarInSelectCropper">
                                                                    <img src={"/assets/img/flags/" + language.language_code + ".svg"} alt="Clara" className="avatarInSelect" />
                                                                </div>
                                                                <div className="avatarInListLabel">{language.readable_label}</div>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row formRow">
                                        <div className="col-sm-6">
                                            <label className="formLabel">TTS Platform</label>
                                            <select name="tts_platform" value={voice.tts_platform ?? "Express"} className="form-control" onChange={handlePlatformChange}>
                                                <option value="Express">Express</option>
                                                <option value="Polly" disabled>
                                                    Polly
                                                </option>
                                                <option value="WaveNet" disabled>
                                                    WaveNet
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="formLabel">Voice Gender</label>
                                            <select name="voice_gender" value={voice.voice_gender} className="form-control" onChange={handleGenderChange}>
                                                <option value="Female">Female</option>
                                                <option value="Male">Male</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row formRow">
                                        <div className="col-sm-12">
                                            <label className="formLabel">&nbsp;</label>
                                            <br />
                                            <button className="btn btn-success addButton" onClick={() => saveVoice()}>
                                                <i className="fa-solid fa-floppy-disk"></i> <div className="addButtonText">Save Voice</div>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
