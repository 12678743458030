import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import VectorVoice from "./components/VectorVoice/VectorVoice";

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(
    <React.StrictMode>
        <VectorVoice />
    </React.StrictMode>
);
