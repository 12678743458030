import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { Grid, Paper, Typography, Box, Menu, MenuItem, Select, InputLabel } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { WithContext as ReactTags } from "react-tag-input";
import { TAGS } from "./tags";

const { v4: uuid_v4 } = require("uuid");
let savedSequenceUUID = "";
let languagesLoaded = false;

export default function Sequence() {
  const [sequenceName, setSequenceName] = useState("");
  const [existingTags, setExistingTags] = useState();
  const [languages, setLanguages] = useState();
  const { project_uuid, sequence_uuid } = useParams();
  const [voices, setVoices] = useState();
  const [wordCount, setWordCount] = useState("0 words used");
  const [sequence, setSequence] = useState({});
  const [sequenceSaved, setSequenceSaved] = useState(false);
  const [project, setProject] = useState({});
  const history = useHistory();
  const [tagEnabled, setTagEnabled] = useState(false);
  const [saveButtonLabel, setSaveButtonLabel] = useState("Save Sequence");

  const [suggestions, setSuggestions] = useState();
  const [savingSequence, setSavingSequence] = useState(false);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const [tags, setTags] = useState([]);

  let s, r;

  function replace_multiple(str, mapObj) {
    let regex = new RegExp("\\b(?:" + Object.keys(mapObj).join("|") + ")\\b", "gi");
    str = str.replace(regex, (matched) => mapObj[matched]);
    return str;
  }

  async function processSpeechAdaptations() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/speech_adaptations_by_length/");
    const arrAdaptations = await response.json();
    s = sequence.sequence_text;
    let mapObj = {};
    Array.prototype.forEach.call(arrAdaptations, function (t) {
      mapObj[t.original_word] = t.adapted_word;
    });
    let newText = replace_multiple(s, mapObj);
    saveSequence(newText);
  }

  const saveSequence = (txt) => {
    setSavingSequence(true);
    setSaveButtonLabel("Processing...");
    console.log("SAVE");

    if (txt !== "") {
      let objPayload = {
        sequence_text: sequence.sequence_text,
        adapted_sequence_text: txt,
        output_format: "mp3",
        sequence_uuid: sequence_uuid,
        project_uuid: project_uuid,
        voice_id: sequence.voice_id,
        voice_uuid: sequence.voice_uuid,
        voice_gender: sequence.voice_gender,
        language_code: sequence.language_code,
        default_seed: sequence.default_seed,
      };
      console.log(objPayload);
      let synthEndpoint;
      console.log("sequence.default_seed=" + sequence.default_seed);
      switch (sequence.tts_platform) {
        case "Polly":
          synthEndpoint = "/api/synthesize_speech";
          break;
        case "RadTTS":
          synthEndpoint = "/api/synthesize_speech_radtts";
          break;
        case "Express":
          synthEndpoint = "/api/synthesize_speech_express";
          objPayload.output_format = "wav";
          break;
        default:
          // defaults to Google TTS for the moment
          synthEndpoint = "/api/synthesize_speech_google";
          break;
      }

      console.log(objPayload);
      axios
        .post(process.env.REACT_APP_API_SERVER_URL + synthEndpoint, objPayload)
        .then((res) => {
          console.log("sequence saved... ", res);
          setSequenceSaved(true);
          savedSequenceUUID = res.data.sequence_uuid;
          setTagEnabled(true);
          setSavingSequence(false);
          setSaveButtonLabel("Save Sequence");
        })
        .catch(() => {});
    } else {
      alert("Sequence Name is required.");
    }
  };

  const playbackSequence = (sequenceeUUID, outputFormat) => {
    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/playback_sequence", {
        sequence_key: project_uuid + "/" + sequenceeUUID + "." + outputFormat,
      })
      .then((data) => {
        console.log(data.data);
        document.getElementById("playPlayer").src = data.data;
      })
      .catch(() => {});
  };

  async function getProject() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/project/" + project_uuid);
    const data = await response.json();
    console.log(data);
    setProject(data);
  }

  async function getTags() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/sequence_tags");
    const data = await response.json();
    console.log(data);
    setExistingTags(data);
    setSuggestions(
      data.map((t) => {
        return {
          id: t.tag_name,
          tag_id: t.id,
          text: t.tag_name,
        };
      })
    );
  }

  async function getLanguages(sequenceData = null) {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_languages");
    const data = await response.json();
    console.log("loading existing to lang", sequenceData);
    setLanguages(data);
    if (sequenceData) {
      getVoices([sequenceData.language_code, sequenceData.language_id], sequenceData);
    } else {
      getVoices(["en-CUSTOM", "32"], null);
    }
  }

  async function getVoices(arrLanguageInfo, sequenceData) {
    console.log("getVoices");
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voices_with_seeds/" + arrLanguageInfo[1]);
    const data = await response.json();
    setVoices(data);
    console.log(data);
    if (sequenceData) {
      console.log("loading existing to voices", sequenceData);
      setSequence({
        ...sequence,
        sequence_text: sequenceData.sequence_text,
        language_id: sequenceData.language_id,
        language_code: sequenceData.language_code,
        output_format: sequenceData.output_format,
        project_uuid: sequenceData.project_uuid,
        voice_id: sequenceData.voice_id,
        voice_uuid: sequenceData.voice_uuid,
        voice_alias: sequenceData.voice_alias,
        tts_platform: sequenceData.tts_platform,
        voice_gender: sequenceData.voice_gender,
        default_seed: sequenceData.default_seed,
      });
    } else {
      console.log(data[0]);
      setSequence({
        ...sequence,
        voice_uuid: data[0].voice_uuid,
        voice_id: data[0].id,
        language_code: arrLanguageInfo[0],
        language_id: arrLanguageInfo[1],
        tts_platform: data[0].tts_platform,
        voice_gender: data[0].voice_gender,
        default_seed: data[0].default_seed,
      });
    }
  }

  const handleLanguageChange = (event) => {
    console.log("set lang to ", event);
    let arrLanguageInfo = event.target.value.split("|");
    console.log(arrLanguageInfo);
    getVoices(arrLanguageInfo);
  };

  const handleTextChange = (event) => {
    setSequence({
      ...sequence,
      sequence_text: event.target.value,
    });

    countWords(event.target.value);
  };

  const handleVoiceChange = (event) => {
    console.log(event);
    let arrVoiceInfo = event.target.value.split("|");
    console.log(arrVoiceInfo);
    setSequence({
      ...sequence,
      voice_uuid: arrVoiceInfo[0],
      voice_id: arrVoiceInfo[1],
      tts_platform: arrVoiceInfo[2],
      voice_gender: arrVoiceInfo[3],
      default_seed: arrVoiceInfo[4],
    });
  };

  async function countWords(s) {
    if (s !== "") {
      s = s.replace(/\n/g, " ");
      s = s.replace(/(^\s*)|(\s*$)/gi, "");
      s = s.replace(/[ ]{2,}/gi, " ");
      let c = s.split(" ").length;
      if (c > 1) {
        setWordCount(c + " words used");
      } else {
        setWordCount(c + " word used");
      }
    } else {
      setWordCount("0 words used");
    }
  }

  async function getSequenceData() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/sequence/" + sequence_uuid);
    const data = await response.json();
    console.log(data);
    languagesLoaded = true;
    getTags();
    getLanguages(data);
    getProject();
    setSequenceSaved(true);
    savedSequenceUUID = data.sequence_uuid;
    countWords(data.sequence_text);
    getSequenceTags();
  }

  async function getSequenceTags() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/sequence_tags/" + sequence_uuid);
    const data = await response.json();
    console.log(data);
    let existingTags = [];
    Array.prototype.forEach.call(data, function (t) {
      existingTags.push({ id: t.tag_name, tag_id: t.id, text: t.tag_name });
    });
    setTags(existingTags);
  }

  const handleTagDelete = (i) => {
    console.log("REMOVED TAG: ", tags[i]);
    setTags(tags.filter((tag, index) => index !== i));
    let objPayload = {
      tag_id: tags[i].tag_id,
    };
    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequence_tags/" + sequence_uuid + "/delete", objPayload)
      .then((res) => {})
      .catch(() => {});
  };

  const checkImage = (path) => {
    console.log("looking for " + path);
    axios
      .get(path)
      .then(() => {
        console.log("FOUND " + path);
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const handleTagAddition = (tag) => {
    console.log("ADDED TAG: ", tag);
    let objPayload = {
      tag_id: tag.tag_id,
      tag_name: tag.text,
    };
    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequence_tags/" + sequence_uuid + "/save", objPayload)
      .then((res) => {
        setTags([...tags, tag]);
      })
      .catch(() => {});
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    console.log("project_uuid=" + project_uuid);
    console.log("sequence_uuid=" + sequence_uuid);

    if (sequence_uuid != "new") {
      getSequenceData(sequence_uuid);
      setTagEnabled(true);
    } else {
      if (!languagesLoaded) {
        console.log("loading languages");
        languagesLoaded = true;
        getTags();
        getLanguages();
        getProject();
      }
    }
  }, []);

  return (
    <Grid container className="mainContainer">
      <Grid container item xs={12} id="page-head">
        <ol className="breadcrumb">
          <li>Projects</li>
          <li>
            <a href={"/project/" + project.project_uuid + "/sequences"}>Sequence Builder</a>
          </li>
          <li>{sequence_uuid === "new" ? "Add" : "Edit"} Sequence</li>
        </ol>
      </Grid>
      <Paper className="mainPanel sequencePage">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h4>{project.project_name || "..."}</h4>
            Enter the details of the new sequence set below.
            <div className="formDivider"></div>
            <div className="row formRow">
              <div className="col-sm-6">
                <label className="formLabel">Language</label>
                {languages && (
                  <>
                    <Select label="Langauge" name="lauguage_code" style={{ minWidth: "100%" }} onChange={handleLanguageChange} value={sequence.language_code + "|" + sequence.language_id}>
                      {languages.map((language) => (
                        <MenuItem disableRipple value={language.language_code + "|" + language.id} key={language.id}>
                          <div className="avatarInSelectCropper">
                            <img src={"/assets/img/flags/" + language.language_code + ".svg"} alt="Clara" className="avatarInSelect" />
                          </div>
                          <div className="avatarInListLabel">{language.readable_label}</div>
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-9">
                    <label className="formLabel">Voice</label>
                  </div>
                  <div className="col-sm-3" style={{ textAlign: "right" }}>
                    <a href={"/voice/" + sequence.voice_uuid + "/files"} className="btn btn-default btn-xs" style={{ padding: "0px 7px", color: "#222 !important" }}>
                      <i className="fa fa-cog" style={{ color: "#222 !important" }}></i>
                    </a>
                  </div>
                </div>

                <div className="row">
                  {" "}
                  <div className="col-sm-12">
                    {voices && (
                      <>
                        <Select label="Voice" name="voice_id" id="voice_id" style={{ minWidth: "100%" }} value={sequence.voice_uuid + "|" + sequence.voice_id + "|" + sequence.tts_platform + "|" + sequence.voice_gender + "|" + sequence.default_seed} onChange={handleVoiceChange}>
                          {voices.map((voice) => (
                            <MenuItem disableRipple value={voice.voice_uuid + "|" + voice.id + "|" + voice.tts_platform + "|" + voice.voice_gender + "|" + voice.default_seed} key={voice.id}>
                              <div className="avatarInSelectCropper">
                                <img src={"/assets/img/avatars/" + voice.avatar + ".jpg"} alt={voice.voice_alias} className="avatarInSelect" />
                              </div>
                              <div className="avatarInListLabel">
                                {voice.voice_alias} (Preset ....{voice.default_seed.slice(-4)})
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row formRow">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-7">
                    <label className="formLabel">Sequence Text</label>
                  </div>
                  <div className="col-sm-5 right" style={{ textAlign: "right !important" }}>
                    <span>{wordCount}</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">{sequence && <textarea style={{ border: "1px solid #ccc", minHeight: "200px" }} className="form-control" name="sequence_text" value={sequence.sequence_text} onChange={handleTextChange} required></textarea>}</div>
            </div>
            <div className="row formRow">
              <div className="col-sm-7">
                {tagEnabled ? (
                  <>
                    <ReactTags autofocus={false} tags={tags} suggestions={suggestions} delimiters={delimiters} handleDelete={handleTagDelete} handleAddition={handleTagAddition} handleTagClick={handleTagClick} inputFieldPosition="bottom" autocomplete />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-sm-5 right" style={{ textAlign: "right !important" }}>
                {sequenceSaved ? (
                  <>
                    <button className="btn btn-primary addButton" onClick={(e) => playbackSequence(savedSequenceUUID, "mp3")}>
                      <i className="fa-solid fa-play"></i> <div className="addButtonText">Listen</div>
                    </button>
                    &nbsp;&nbsp;
                  </>
                ) : (
                  <></>
                )}
                {savingSequence ? (
                  <>
                    <button disabled="disabled" className="btn btn-warning">
                      <svg height="2.1em" width="50" class="icon-loading" viewBox="0 0 155 140">
                        <rect y="10" width="15" height="120" rx="6" fill="#FFF" stroke="transparent" fill-opacity="1" stroke-opacity="1">
                          <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"></animate>
                          <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"></animate>
                        </rect>
                        <rect x="30" y="10" width="15" height="120" rx="6" fill="#FFF" stroke="transparent" fill-opacity="1" stroke-opacity="1">
                          <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"></animate>
                          <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"></animate>
                        </rect>
                        <rect x="60" width="15" height="140" rx="6" fill="#FFF" stroke="transparent" fill-opacity="1" stroke-opacity="1">
                          <animate attributeName="height" begin="0s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"></animate>
                          <animate attributeName="y" begin="0s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"></animate>
                        </rect>
                        <rect x="90" y="10" width="15" height="120" rx="6" fill="#FFF" stroke="transparent" fill-opacity="1" stroke-opacity="1">
                          <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"></animate>
                          <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"></animate>
                        </rect>
                        <rect x="120" y="10" width="15" height="120" rx="6" fill="#FFF" stroke="transparent" fill-opacity="1" stroke-opacity="1">
                          <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite"></animate>
                          <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite"></animate>
                        </rect>
                      </svg>
                      <div className="addButtonText">{saveButtonLabel}</div>
                    </button>
                  </>
                ) : (
                  <button className="btn btn-success addButton" onClick={() => processSpeechAdaptations()}>
                    <i className="fa fa-floppy-disk"></i>
                    <div className="addButtonText">{saveButtonLabel}</div>
                  </button>
                )}
                <ReactAudioPlayer id="playPlayer" src="" autoPlay controls style={{ display: "none" }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
