import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Grid, Paper, Typography, Box, Menu, MenuItem, Select, InputLabel } from "@mui/material";

const { v4: uuid_v4 } = require("uuid");

export default function SpeechAdaptation() {
  const { id } = useParams();
  const [adaptation, setAdaptation] = useState("");
  const history = useHistory();
  let voiceUUID = uuid_v4();
  let voiceAlias = useRef(null);
  let voiceName = useRef(null);
  const [file, setFile] = useState(null);
  const [languages, setLanguages] = useState();

  async function getData() {
    if (id != "new") {
      const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/speech_adaptation/" + id);
      const data = await response.json();
      setAdaptation(data);
      console.log(data);
    } else {
      setAdaptation({
        ...adaptation,
        original_word: "",
        adapted_word: "",
      });
    }
  }

  const inputRef = useRef();

  const handleOriginalWordChange = (e) => {
    setAdaptation({
      ...adaptation,
      original_word: e.target.value,
    });
  };

  const handleAdaptedWordChange = (e) => {
    setAdaptation({
      ...adaptation,
      adapted_word: e.target.value,
    });
  };

  const handleCaseSensitiveChange = (e) => {
    setAdaptation({
      ...adaptation,
      case_sensitive: e.target.value,
    });
  };

  const saveAdaptation = () => {
    let adaptation_id;
    if (!id) {
      adaptation_id = "new";
    } else {
      adaptation_id = id;
    }
    if (adaptation.original_word !== "" && adaptation.adapted_word !== "") {
      let objPayload = {
        id: adaptation_id,
        original_word: adaptation.original_word,
        adapted_word: adaptation.adapted_word,
        case_sensitive: adaptation.case_sensitive,
      };

      console.log(objPayload);
      axios
        .post(process.env.REACT_APP_API_SERVER_URL + "/api/speech_adaptation/save", objPayload)
        .then((res) => {
          console.log("voice saved... ", res);
          history.push("/settings/speech_adaptations");
        })
        .catch(() => {});
    } else {
      alert("Original Word and Adapted Word are required.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container className="mainContainer">
      <Grid container xs={12} id="page-head">
        <Grid item xs={7}>
          <ol className="breadcrumb">
            <li>Settings</li>
          </ol>
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>

      <Box xs={12} className="mainPanel">
        <div className="panel panel-success panel-bordered wizardPanel">
          <div className="panel-body">
            <div className="row pageHeader">
              <div className="col-sm-12">
                <h4>
                  <a href="/settings/speech_adaptations">Speech Adaptations</a>: Edit Speech Adaptation
                </h4>
                Enter the details of the speech adaptation below.
                <div className="formDivider"></div>
                {adaptation && (
                  <>
                    <div className="row formRow">
                      <div className="col-sm-6">
                        <label className="formLabel">Original Word</label>
                        <input type="text" value={adaptation.original_word} className="form-control" onChange={handleOriginalWordChange} autoFocus />
                      </div>
                      <div className="col-sm-6">
                        <label className="formLabel">Adapted Word</label>
                        <input type="text" value={adaptation.adapted_word} className="form-control" onChange={handleAdaptedWordChange} />
                      </div>
                    </div>
                    <div className="row formRow">
                      <div className="col-sm-6">
                        <label className="formLabel">Case Sensitive?</label>
                        <select value={adaptation.case_sensitive} className="form-control" onChange={handleCaseSensitiveChange}>
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label className="formLabel">&nbsp;</label>
                        <br />
                        <button className="btn btn-success addButton" onClick={() => saveAdaptation()}>
                          <i className="fa-solid fa-floppy-disk"></i> <div className="addButtonText">Save Adaptation</div>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Grid>
  );
}
