//
//
import { Grid, Paper, Typography, Box, Divider, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Button, Modal, Slider, FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton, Select, MenuItem, Checkbox } from "@mui/material";
import ContentEditable from "react-contenteditable";
import React, { useEffect, useState } from "react";

//
import { ClipControls } from "./ClipControls";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import FastForwardIcon from "@mui/icons-material/FastForward";
import ScaleLoader from "react-spinners/ScaleLoader";

let languageCode;
let arrLanguage;
let langColor = "";
let languageLabel;

export function ClipRow({ inferToucanSequence, handleCheckboxChange, selectedChoices, setSelectedChoices, sequence, handleEffectButtonClick, updateEffectLabel, handleEffectChange, itemIndex, handleStyleChange, i, handleClipRowClick, openVoiceModal, handleClipTextClick, handleClipTextPaste, handleClipTextBlur, handleClipTextChange, playbackSequence, inferSequence, downloadSequence, deleteSequence }) {
    const lookforChecked = (e) => {
        console.log(e);
    };

    //    languageCode = sequence.language_code;
    //    arrLanguage = languageCode.split("-");
    //    console.log("LANG", arrLanguage);
    //    langColor = "";
    //    languageLabel = arrLanguage[0];
    //    console.log(languageLabel);
    //    switch (languageLabel) {
    //        case "en":
    langColor = "Blue";
    //            break;
    //        case "es":
    //            langColor = "Orange";
    //           break;
    //    }

    return (
        <>
            <Grid container className="clipRow" key={sequence.sequence_uuid} data-inferred={sequence.inferred} data-sequence_uuid={sequence.sequence_uuid} data-item_index={i} onClick={(e) => handleClipRowClick(e, sequence.sequence_uuid)}>
                <div className="spinnerContainer" data-sequence_uuid={sequence.sequence_uuid}>
                    <ScaleLoader color="#36d7b7" className="spinner" />
                </div>
                <Grid item xs={1} className="clipRowDraggerHolder">
                    <div className="clipRowDragger">
                        <DragIndicatorIcon />
                    </div>
                    <div className="clipRowSelector">
                        <Checkbox type="checkbox" value={sequence.sequence_uuid} className="clipRowCheckbox" onChange={() => handleCheckboxChange(sequence.sequence_uuid)} checked={selectedChoices.includes(sequence.sequence_uuid)} />
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div className="voiceAvatarColumn">
                        <div className="row" style={{ marginRight: "15px" }}>
                            <div className="col-sm-5 avatarSpacer">
                                <div className="avatarInListCropper">
                                    <img src={"/assets/img/avatars/" + sequence.avatar + ".jpg"} alt={sequence.voice_alias} className="avatarInList avatarInListLink" onClick={(e) => openVoiceModal(sequence.sequence_uuid, sequence.voice_id)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="avatarLabelInRow">{sequence.voice_alias}</div>
                </Grid>
                <Grid item xs={7}>
                    <div className="minEffectLabels" data-sequence_uuid={sequence.sequence_uuid}>
                        <div className={"languageLabel languageLabel" + langColor}>{languageLabel}</div>
                        <MusicNoteIcon />
                        {sequence.pitch}%
                        <FastForwardIcon />
                        {sequence.tempo}% &nbsp;&nbsp;&nbsp;{sequence.style}
                    </div>
                    <ClipControls languageLabel={languageLabel} languageCode={languageCode} langColor={langColor} sequence={sequence} handleEffectButtonClick={handleEffectButtonClick} updateEffectLabel={updateEffectLabel} handleEffectChange={handleEffectChange} itemIndex={i} handleStyleChange={handleStyleChange} />
                    <div
                        data-tts_platform={sequence.tts_platform}
                        data-inferred={sequence.inferred}
                        data-random_key={sequence.random_key}
                        data-sequence_uuid={sequence.sequence_uuid}
                        onClick={(e) => handleClipTextClick(e, sequence.sequence_uuid)}
                        className="clipTextInput editable editable-real"
                        spellCheck="true"
                        placeholder="Enter your text here"
                        onPaste={handleClipTextPaste}
                        onBlur={(e) => handleClipTextBlur(e, sequence.sequence_uuid, i)}
                        onInput={handleClipTextChange}
                        contentEditable="true"
                        suppressContentEditableWarning="true"
                        style={{ width: "100%" }}
                    >
                        {sequence.sequence_text}
                    </div>
                </Grid>
                <Grid item xs={3} className="clipRowActions">
                    {sequence.inferred == 1 ? (
                        <>
                            <button
                                data-sequence_uuid={sequence.sequence_uuid}
                                className="btn btn-sm clipRowActionButton clipRowPlayInferred"
                                onClick={(e) => {
                                    if (sequence.inferred === 1) {
                                        playbackSequence(sequence.sequence_uuid, sequence.random_key, "mp3");
                                    } else {
                                        if (sequence.tts_platform == "Express") {
                                            inferSequence(sequence.sequence_uuid);
                                        } else {
                                            inferToucanSequence(sequence.sequence_uuid);
                                        }
                                    }
                                }}
                            >
                                <i id={"playBtn_" + sequence.sequence_uuid} className="playBtnInList fa-solid fa-fw fa-play"></i>
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                data-sequence_uuid={sequence.sequence_uuid}
                                className="btn btn-sm btn-default clipRowActionButton"
                                onClick={(e) => {
                                    if (sequence.inferred === 1) {
                                        playbackSequence(sequence.sequence_uuid, sequence.random_key, "mp3");
                                    } else {
                                        if (sequence.tts_platform == "Express") {
                                            inferSequence(sequence.sequence_uuid);
                                        } else {
                                            inferToucanSequence(sequence.sequence_uuid);
                                        }
                                    }
                                }}
                            >
                                <i id={"playBtn_" + sequence.sequence_uuid} className="playBtnInList fa-solid fa-fw fa-play"></i>
                            </button>
                        </>
                    )}
                    &nbsp;
                    <button className="btn btn-sm btn-default clipRowActionButton" onClick={(e) => downloadSequence(sequence.sequence_uuid, sequence.random_key, sequence.output_format)}>
                        <i className="fa fa-download"></i>
                    </button>
                    &nbsp;
                    <button className="btn btn-sm btn-default clipRowActionButton" onClick={(e) => deleteSequence(sequence.sequence_uuid, true)}>
                        <i className="fa fa-trash"></i>
                    </button>
                    <br />
                    <div className="sequenceStatus" data-sequence_uuid={sequence.sequence_uuid}>
                        <div className="statusHolder">
                            <div className="statusLabel">{sequence.duration && sequence.duration != "" ? <>{Math.round(sequence.duration * 100) / 100 + " sec"}</> : <>unsaved</>}</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
