import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
const { v4: uuid_v4 } = require("uuid");

export default function NewProject() {
    const [projectName, setProjectName] = useState("");
    const history = useHistory();
    let projectUUID = uuid_v4();
    const saveBaseProject = () => {
        if (projectName != "") {
            const objUser = JSON.parse(sessionStorage.getItem("user"));
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/projects/save", {
                    project_name: projectName,
                    project_uuid: projectUUID,
                    status: "new",
                    speaker_id: Math.floor(1000 + Math.random() * 9000),
                    user_uuid: objUser.user_uuid,
                })
                .then(() => {
                    history.push("/studio/record/" + projectUUID);
                })
                .catch(() => {});
        } else {
            alert("Project Name is required.");
        }
    };

    return (
        <div className="page wizardPage">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb"></ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered wizardPanel">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-12">
                            <h4>New Project</h4>
                            Enter the details of the new project set below.
                            <div className="formDivider"></div>
                            <div className="row formRow">
                                <div className="col-sm-12">
                                    <label className="formLabel">Project Name</label>
                                    <input type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)} className="form-control" autoFocus />
                                </div>
                            </div>
                            <label className="formLabel">Project Source</label> ( Click one to continue )
                            <div className="row formRow">
                                <div className="col-sm-6">
                                    <div className="bigIconButton">
                                        <span onClick={() => saveBaseProject()}>
                                            <i className="fa fa-microphone"></i>
                                            <br></br>
                                            <h6 className="bigIconTitle">Record your project</h6>
                                            Use your microphone to record
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className=" bigIconButton">
                                        <i className="fa fa-upload"></i>
                                        <br></br>
                                        <h6 className="bigIconTitle">Upload audio files</h6>
                                        Load pre-recorded clips of your project
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
