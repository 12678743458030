import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { Grid, Paper, Typography, Box, TextField, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VoiceMenu from "../Common/VoiceMenu";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

export default function Studio() {
  const [voices, setVoices] = useState(null);
  const [voiceName, setVoiceName] = useState("");
  const [voiceUUID, setVoiceUUID] = useState("new");
  const [voiceDialogTitle, setVoiceDialogTitle] = useState(null);
  const [voiceDialogOpen, setVoiceDialogOpen] = useState(false);

  const updateVoiceName = (e) => {
    setVoiceName(e.target.value);
  };

  const saveBaseVoice = () => {
    const objUser = JSON.parse(sessionStorage.getItem("user"));
    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/voices/save", {
        voice_name: voiceName,
        voice_uuid: voiceUUID,
        status: "new",
        speaker_id: Math.floor(1000 + Math.random() * 9000),
        user_uuid: objUser.user_uuid,
      })
      .then(() => {
        handleClose();
        getData();
      })
      .catch(() => {});
  };

  const deleteVoice = (id) => {
    Swal.fire({
      title: "Remove this Voice?",
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: "#CC0000",
      showClass: {
        popup: "",
      },
      hideClass: {
        popup: "",
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post(process.env.REACT_APP_API_SERVER_URL + "/api/voices/" + id + "/delete", {
            voice_uuid: id,
          })
          .then(() => {
            getData();
          })
          .catch(() => {});
      }
    });
  };

  const handleClose = () => {
    setVoiceDialogOpen(false);
  };

  const handleVoiceDialogOpen = (id, name = "") => {
    if (id === "new") {
      setVoiceDialogTitle("Add New Voice");
    } else {
      setVoiceDialogTitle("Edit Voice Details");
    }
    setVoiceUUID(id);
    setVoiceName(name);
    setVoiceDialogOpen(true);
  };

  async function getData() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/voices");
    const data = await response.json();
    console.log(data);
    setVoices(data);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid container className="mainContainer">
        <Grid container xs={12} id="page-head">
          <Grid item xs={7}>
            <ol className="breadcrumb">
              <li>Studio</li>
            </ol>
          </Grid>
          <Grid item xs={5}>
            <div className="alignRight topMenuRight">
              <span onClick={() => handleVoiceDialogOpen("new", "")} className="btn-sm btn btn-success addButton">
                <ControlPointIcon /> <div className="addButtonText">Add Voice</div>
              </span>
            </div>
          </Grid>
        </Grid>

        <Box xs={12} className="mainPanel">
          <Grid container spacing={2}>
            {voices && (
              <>
                {voices.map((voice) => (
                  <Paper key={voice.voice_uuid} xs={12} className="projectBox darkProjectBox">
                    <Grid container spacing={0}>
                      <Grid item xs={9}>
                        <h4>{voice.voice_name}</h4>
                        {moment(voice.created_at).format("MM/DD/YYYY hh:mm:ssA")}
                      </Grid>
                      <Grid item xs={3} style={{ position: "absolute", right: "10px" }}>
                        <VoiceMenu voice={voice} handleVoiceDialogOpen={handleVoiceDialogOpen} />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <a className="btn-sm btn btn-success addButton" href={"/studio/record/" + voice.voice_uuid}>
                      <i className="fa-solid fa-microphone"></i> <div className="addButtonText">Recordings</div>
                    </a>
                  </Paper>
                ))}
              </>
            )}
          </Grid>
        </Box>
      </Grid>

      <Dialog open={voiceDialogOpen} onClose={handleClose}>
        <DialogTitle>{voiceDialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Voice Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={updateVoiceName}
            onKeyUp={(event) => {
              if (event.key === "Enter") saveBaseVoice();
            }}
            value={voiceName}
          />
        </DialogContent>
        <DialogActions>
          <Button className="cancelButton" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={saveBaseVoice}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
