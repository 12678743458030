import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Grid, Paper, Typography, Box, Menu, MenuItem, Select, InputLabel, Switch, FormGroup, FormControlLabel, FormControl, Button, ListItemIcon, ListItemText } from "@mui/material";
import AudioPlayer from "react-h5-audio-player";
import InputIcon from "@mui/icons-material/Input";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import NoiseAwareIcon from "@mui/icons-material/NoiseAware";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SpeakerGroupIcon from "@mui/icons-material/SpeakerGroup";
import SpatialAudioIcon from "@mui/icons-material/SpatialAudio";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PublishIcon from "@mui/icons-material/Publish";
import { EffectsMenu } from "./EffectsMenu";
import { PitchMenu } from "./PitchMenu";
import { TempoMenu } from "./TempoMenu";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import MicIcon from "@mui/icons-material/Mic";
import Swal from "sweetalert2";

const { v4: uuid_v4 } = require("uuid");

export default function VoiceFiles() {
    const { id } = useParams();
    const [voice, setVoice] = useState("");
    const history = useHistory();
    let voiceUUID = uuid_v4();
    let voiceAlias = useRef(null);
    let voiceName = useRef(null);
    const [file, setFile] = useState(null);
    const [voiceSourceFiles, setVoiceSourceFiles] = useState();
    const [voiceSelectedFiles, setVoiceSelectedFiles] = useState();
    const [voiceSamples, setVoiceSamples] = useState();
    const [filesProcessed, setFilesProcessed] = useState(null);
    const [totalFiles, setTotalFiles] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    async function applyMaxineEffects(strVoiceUUID, strFileName, strEffect) {
        setAnchorEl(null);
        Swal.fire({
            title: "Applying Effect",
            html: "Applying the " + strEffect + " effect to clip...",
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
        });

        let formData = new FormData();
        formData.append("voiceUUID", strVoiceUUID);
        formData.append("fileName", strFileName);
        formData.append("effect", strEffect);
        axios({
            method: "POST",
            url: process.env.REACT_APP_PROCESS_SERVER_URL + "/denoise/process_single_effect.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            console.log(response.data.path);
            //      let p = response.data.path;
            //      p = p.replace("/var/www/html", "https://nest.falconsnest.dev");
            //      console.log(p);
            Swal.close();
            getFiles(voice.voice_uuid, "voice_wavs_selected");
        });
    }

    async function getData() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice/" + id);
        const data = await response.json();
        console.log(data);
        setVoice(data);
        getFiles(data.voice_uuid, "voice_wavs_source");
        getFiles(data.voice_uuid, "voice_wavs_selected");
        getVoiceSamples(data.voice_uuid);
    }

    const handleEffectChange = (e, voiceFile) => {
        console.log("handleEffectChange", e);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePitchChange = () => {};

    const publishVoice = () => {
        console.log("-----------UPLOADING----------");

        let sweetAlertStatusBox;

        Swal.fire({
            title: "Uploading",
            html: "Submitting your voice files for processing...",
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                sweetAlertStatusBox = Swal.getHtmlContainer().querySelector("b");
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
        });

        console.log("updateFiles");

        Swal.getTitle().textContent = "Processing...";
        Swal.getHtmlContainer().textContent = "Synthesizing your new voice files into samples and creating presets.";
        let formData = new FormData();
        formData.append("voice_uuid", voice.voice_uuid);
        axios({
            method: "POST",
            url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/publish.php",
            data: formData,
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            (res) => {
                console.log("upload RES", res);
                if (res.data.status && res.data.status == "seeded") {
                    Swal.getTitle().textContent = "Saved!";
                    Swal.getHtmlContainer().textContent = "The voice is saved. Refreshing page...";
                    setTimeout(2000);
                    window.location.reload();
                }
            },
            (err) => err
        );
        // add file information to database
    };

    async function getFiles(voiceName, fileType) {
        const body = { voice_uuid: voiceName, voice_file_type: fileType };
        console.log("files thing", body);
        fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice_files/", {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                console.log(data);
                switch (fileType) {
                    case "voice_wavs_source":
                        setVoiceSourceFiles(data);
                        break;
                    case "voice_wavs_selected":
                        setVoiceSelectedFiles(data);
                        break;
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    async function setDefaultPreset(voice_seed_uuid) {
        const body = { voice_seed_uuid: voice_seed_uuid, voice_uuid: voice.voice_uuid };

        fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice_seed_set_default/", {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                console.log(data);
                getVoiceSamples(voice.voice_uuid);
                getFiles(voice.voice_uuid, "voice_wavs_source");
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    async function getVoiceSamples(voice_uuid) {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice_seeds/" + voice_uuid);
        const data = await response.json();
        console.log(data);
        console.log("Voice Samples:", data);
        console.log(data);
        setVoiceSamples(data);
    }

    const inputRef = useRef();

    const deleteSourceFile = (strFileName) => {
        let formData = new FormData();
        formData.append("voice_uuid", voice.voice_uuid);
        formData.append("file_name", strFileName);
        axios({
            method: "POST",
            url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/delete_source_file.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(
            (res) => {
                console.log("delete RES", res);
                getFiles(voice.voice_uuid, "voice_wavs_source");
            },
            (err) => err
        );
        // add file information to database
    };

    const denoiseSelectedFile = (strFileName) => {
        console.log(strFileName);
        applyMaxineEffects(voice.voice_uuid, strFileName, "denoiser");
        handleClose();
    };

    const dereverbSelectedFile = (strFileName) => {
        applyMaxineEffects(voice.voice_uuid, strFileName, "dereverb");
    };

    const openPitchMenu = (strFileName) => {};

    const deleteSelectedFile = (strFileName) => {
        let formData = new FormData();
        formData.append("voice_uuid", voice.voice_uuid);
        formData.append("file_name", strFileName);
        axios({
            method: "POST",
            url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/delete_selected_file.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(
            (res) => {
                console.log("delete RES", res);
                getFiles(voice.voice_uuid, "voice_wavs_selected");
            },
            (err) => err
        );
        // add file information to database
    };

    async function applyEffect(strFileName, strEffect, strAmount) {
        Swal.fire({
            title: "Applying Mods",
            html: "Modifying the clip's pitch...",
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
        });

        console.log("amount=", strAmount);

        let formData = new FormData();
        formData.append("voiceUUID", voice.voice_uuid);
        formData.append("fileName", strFileName);
        formData.append("effect", strEffect);
        formData.append("amount", strAmount);
        axios({
            method: "POST",
            url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/apply_sox_effect.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            console.log(response.data.path);
            //      let p = response.data.path;
            //      p = p.replace("/var/www/html", "https://nest.falconsnest.dev");
            //      console.log(p);
            Swal.close();
            getFiles(voice.voice_uuid, "voice_wavs_selected");
        });
    }

    const selectSourceFile = (strFileName) => {
        let formData = new FormData();
        formData.append("voice_uuid", voice.voice_uuid);
        formData.append("file_name", strFileName);
        axios({
            method: "POST",
            url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/select_source_file.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(
            (res) => {
                console.log("select RES", res);
                getFiles(voice.voice_uuid, "voice_wavs_source");
                getFiles(voice.voice_uuid, "voice_wavs_selected");
            },
            (err) => err
        );
        // add file information to database
    };

    const uploadSourceFiles = () => {
        console.log("-----------UPLOADING----------");

        let sweetAlertStatusBox;

        Swal.fire({
            title: "Uploading",
            html: "Submitting your voice files for processing...",
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                sweetAlertStatusBox = Swal.getHtmlContainer().querySelector("b");
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
        });

        console.log("updateFiles");
        let filesProcessed;
        let totalFiles = inputRef.current.files.length;
        for (let i = 0; i < totalFiles; i++) {
            filesProcessed = i + 1;
            console.log("filesProcessed=" + filesProcessed);
            setFilesProcessed(filesProcessed);
            setTotalFiles(totalFiles);
            let formData = new FormData();
            formData.append("voice_uuid", voice.voice_uuid);
            formData.append("voice_name", voice.voice_name);
            formData.append("files", inputRef.current.files[i]);
            formData.append("filesProcessed", filesProcessed);
            formData.append("totalFiles", totalFiles);
            Swal.getTitle().textContent = "Processing...";
            Swal.getHtmlContainer().textContent = "Synthesizing your new voice files into samples and creating presets.";
            axios({
                method: "POST",
                url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/upload_source_files.php",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(
                (res) => {
                    console.log("upload RES", res);
                    Swal.close();
                    getFiles(voice.voice_uuid, "voice_wavs_source");
                },
                (err) => err
            );
        }
        // add file information to database
    };

    const updateFiles = () => {
        console.log("-----------UPLOADING----------");

        let sweetAlertStatusBox;

        Swal.fire({
            title: "Uploading",
            html: "Submitting your voice files for processing...",
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                sweetAlertStatusBox = Swal.getHtmlContainer().querySelector("b");
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
        });

        console.log("updateFiles");
        let filesProcessed;
        let totalFiles = inputRef.current.files.length;
        for (let i = 0; i < totalFiles; i++) {
            filesProcessed = i + 1;
            console.log("filesProcessed=" + filesProcessed);
            setFilesProcessed(filesProcessed);
            setTotalFiles(totalFiles);
            let formData = new FormData();
            formData.append("voice_uuid", voice.voice_uuid);
            console.log("files", inputRef.current.files);
            formData.append("voice_name", voice.voice_name);
            console.log("adding file", inputRef.current.files[i]);
            formData.append("files", inputRef.current.files[i]);
            formData.append("filesProcessed", filesProcessed);
            formData.append("totalFiles", totalFiles);
            Swal.getTitle().textContent = "Processing...";
            Swal.getHtmlContainer().textContent = "Synthesizing your new voice files into samples and creating presets.";
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voice_files/save", {
                    voice_id: 15,
                })
                .then((data) => {
                    // send files to inference server
                    console.log("sending: ", formData);
                    axios({
                        method: "POST",
                        url: process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/upload.php",
                        data: formData,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then(
                        (res) => {
                            console.log("upload RES", res);
                            if (res.data.status && res.data.status == "seeded") {
                                Swal.getTitle().textContent = "Saved!";
                                Swal.getHtmlContainer().textContent = "The voice is saved. Refreshing page...";
                                setTimeout(2000);
                                window.location.reload();
                            }
                        },
                        (err) => err
                    );
                })
                .catch(() => {});
        }
        // add file information to database
    };

    const handleAliasChange = (e) => {
        setVoice({
            ...voice,
            voice_alias: e.target.value,
        });
    };

    const handleNameChange = (e) => {
        setVoice({
            ...voice,
            voice_name: e.target.value,
        });
    };

    const handleGenderChange = (e) => {
        setVoice({
            ...voice,
            voice_gender: e.target.value,
        });
    };

    const handlePlatformChange = (e) => {
        setVoice({
            ...voice,
            voice_platform: e.target.value,
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const handleVisibilityChange = (e) => {};

    return (
        <Grid container className="mainContainer">
            <Grid container id="page-head">
                <Grid item xs={7}>
                    <ol className="breadcrumb">
                        <li>Voices</li>
                    </ol>
                </Grid>
                <Grid item xs={5}></Grid>
            </Grid>

            <Box xs={12} className="mainPanel">
                <Grid container>
                    <Grid item xs={6}>
                        <h4>
                            <div className="voiceNameEdit">{voice.voice_alias}</div>
                        </h4>
                        Load audio files for the voice set below. Your files will be converted to 16-bit mono WAV at 22,050hz.
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <Button className="publishVoiceButton" onClick={(e) => publishVoice(e)}>
                            <PublishIcon />
                            Publish Voice
                        </Button>
                        <br />
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl sx={{ m: 1, minWidth: "100%", backgroundColor: "#FFF" }} size="small">
                                    <InputLabel id="demo-select-small">Visibility</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={"Public"} label="Age" onChange={handleVisibilityChange}>
                                        <MenuItem value={"Public"}>Public</MenuItem>
                                        <MenuItem value={"Private"}>Private</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className="formDivider"></div>
                <Grid container>
                    <Grid item xs={6} className="audioColumn audioColumnLeft">
                        <Grid container>
                            <Grid item xs={6}>
                                <b className="audioColumnHeader">Source Files</b>
                                <br />
                                Add files to use with this voice.
                            </Grid>
                            <Grid item xs={6} className="right">
                                <label htmlFor="fileInput" className="fileInputLabel">
                                    <div className="btn btn-succes uploadButton extraSmallButton sourceAudioButton lightGreenFadeBg">
                                        <DriveFolderUploadIcon />
                                        <br />
                                        Upload
                                    </div>
                                    <input ref={inputRef} id="fileInput" type="file" name="file" onChange={(e) => uploadSourceFiles()} multiple />
                                </label>
                            </Grid>
                            <Grid item xs={12}></Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {voiceSourceFiles && (
                                <>
                                    {voiceSourceFiles.map((voiceFile) => (
                                        <div key={voiceFile} className="row voiceFileLine">
                                            <Grid container>
                                                <Grid item xs={2} style={{ width: "50px", maxWidth: "50px" }}>
                                                    <AudioPlayer
                                                        // style={{ width: "300px" }}
                                                        style={{ borderRadius: "4px", width: "50px" }}
                                                        // layout="horizontal"
                                                        src={process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/voice_wavs_source/" + voice.voice_uuid + "/" + voiceFile}
                                                        onPlay={(e) => console.log("onPlay")}
                                                        showSkipControls={false}
                                                        showJumpControls={false}
                                                        layout="horizontal"
                                                        // other props here
                                                    />
                                                </Grid>
                                                <Grid item xs={8} style={{ overflow: "hidden" }} className="voiceFileNameColumn">
                                                    {voiceFile}
                                                </Grid>
                                                <Grid item xs={2} className="audioActionButtonsColumn">
                                                    <Button className="extraSmallButton danger btnRemoveAudio" onClick={(e) => deleteSourceFile(voiceFile)}>
                                                        <DeleteForeverIcon />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className="audioColumn audioColumnCenter">
                        <Grid container>
                            <Grid item xs={6}>
                                <b className="audioColumnHeader">Available Presets</b>
                                <br /> Preview and select a default preset from this list.
                            </Grid>
                            <Grid item xs={6} className="right"></Grid>
                            <Grid item xs={12} className="voicePresetsContainer">
                                <>
                                    {voiceSamples && (
                                        <>
                                            <Grid container>
                                                {voiceSamples.map((voiceSample) => (
                                                    <>
                                                        <Grid item xs={3}>
                                                            <div key={voiceSample.voice_seed_uuid} className={voiceSample.default_seed == 1 ? "row voicePresetBox voiceFileLineDefault" : "row voicePresetBox"}>
                                                                <div className="col-sm-12">
                                                                    <div style={{ width: "50px", float: "left" }}>
                                                                        <AudioPlayer
                                                                            // style={{ width: "300px" }}
                                                                            style={{ borderRadius: "1rem", width: "50px" }}
                                                                            // layout="horizontal"
                                                                            src={process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/voice_samples/" + voice.voice_uuid + "/" + voiceSample.seed + ".wav"}
                                                                            onPlay={(e) => console.log("onPlay")}
                                                                            showSkipControls={false}
                                                                            showJumpControls={false}
                                                                            layout="horizontal"
                                                                            // other props here
                                                                        />
                                                                    </div>
                                                                    <div style={{ float: "left", marginTop: "3px" }}>{voiceSample.label.slice(-4)}</div>
                                                                    {voiceSample.default_seed == 1 ? (
                                                                        <>
                                                                            {" "}
                                                                            <div className="setDefaultSample defaultSample">DEFAULT PRESET</div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {" "}
                                                                            <div className="setDefaultSample" onClick={() => setDefaultPreset(voiceSample.voice_seed_uuid)}>
                                                                                SET AS DEFAULT
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </>
                                    )}
                                </>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <div className="row formRow">
                    <div className="col-sm-12">
                        {uploadStatus && (
                            <>
                                <br />
                                <div className="uploadStatus">
                                    Status: <b>{uploadStatus}</b>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Box>
        </Grid>
    );
}
