import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";
import { Grid, Paper, Typography, Box } from "@mui/material";

import { v4 as uuid_v4 } from "uuid";
const S3_BUCKET = "voice-transcribe-dev";
const REGION = "us-east-2";
AWS.config.update({
    accessKeyId: "AKIA3XR4ZLPB37W2TI2O",
    secretAccessKey: "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",
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export default function Record() {
    let metaDataText = "";
    let { voice_uuid } = useParams();
    const [playbackAudio, setPlaybackAudio] = useState();
    const [voiceUtterances, setVoiceUtterances] = useState();
    let [recordingState, setRecordingState] = useState("stop");
    let [voiceData, setVoiceData] = useState({
        ttl_utterances: 0,
        minimum_utterances: 0,
        pct_complete: 0,
        voice_ready: false,
        status: "new",
        voice_name: "",
    });
    let [audioData, setAudioData] = useState(null);
    const [metadata, setMetadata] = useState({
        metadata_id: 0,
        content: "",
    });

    const start = () => {
        setRecordingState("start");
    };

    const stop = () => {
        setRecordingState("stop");
    };

    const playbackUtterance = (utteranceUUID) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/playback_utterance", {
                voice_uuid: voice_uuid,
                utterance_uuid: utteranceUUID,
                output_path: utteranceUUID + ".wav",
            })
            .then((data) => {
                console.log(data.data);
                document.getElementById("playPlayer").src = data.data;
            })
            .catch(() => {});
    };

    const playbackSample = (wavPath) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/playback_sample", {
                wav_path: wavPath,
            })
            .then((data) => {
                console.log(data.data);
                document.getElementById("samplePlayer").src = data.data;
            })
            .catch(() => {});
    };

    const sendToTraining = () => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/voices/send_to_training", {
                voice_uuid: voice_uuid,
            })
            .then(() => {
                getMetadata();
                getVoiceInfo();
                setRecordingState("stop");
                setAudioData(null);
            })
            .catch(() => {});
    };

    const downloadVoiceZIP = () => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/get_voice_files", {
                voice_uuid: voice_uuid,
            })
            .then((zipAsBase64) => {
                console.log(zipAsBase64);
                const blob = b64ToBlob(zipAsBase64, "application/zip");
                fileSaver.saveAs(blob, voice_uuid + ".zip");
            })
            .catch(() => {});
    };

    const skip = () => {
        getMetadata();
        setRecordingState("stop");
        setAudioData(null);
    };

    async function save() {
        // save to db, and send file to node for processing and upload to S3

        let utteranceUUID = uuid_v4();
        let metaDataPath = voice_uuid + "/metadata.txt";
        let audioFilePath = voice_uuid + "/" + utteranceUUID + ".wav";

        const myFile = new File([audioData.blob], audioFilePath, {
            type: audioData.blob.type,
        });

        // add WAV file and data fields to form
        const formData = new FormData();
        formData.append("wav_file", myFile);
        formData.append("utterance_uuid", utteranceUUID);
        formData.append("voice_uuid", voice_uuid);
        formData.append("metadata_id", metadata.metadata_id);

        // post form with WAV file to node
        await axios({
            method: "post",
            url: process.env.REACT_APP_API_SERVER_URL + "/api/voices/save_utterance",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
            data: formData,
        }).then(() => {
            // get the new utterances for UI and to push metadata file
            getVoiceUtterances().then((arrUtterances) => {
                for (const [i, v] of arrUtterances.entries()) {
                    metaDataText += voice_uuid + "/" + arrUtterances[i].utterance_uuid + ".wav|" + arrUtterances[i].content + "|" + voiceData.speaker_id;
                    if (i !== arrUtterances.length - 1) {
                        metaDataText += "\n";
                    }
                }

                let metaDataBlob = new Blob([metaDataText], { type: "text/plain;charset=utf-8" });
                let metaDataFile = new File([metaDataBlob], metaDataPath, {
                    type: metaDataBlob.type,
                });

                // send metadata file to S3
                sendToS3({
                    ACL: "private",
                    Body: metaDataFile,
                    Bucket: S3_BUCKET,
                    Key: "training/" + metaDataPath,
                });

                getVoiceInfo();
                setRecordingState("stop");
                setAudioData(null);
                metaDataText = "";
            });
        });
    }
    /*
    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/voices/save_utterance", {
        voice_uuid: voice_uuid,
        metadata_id: metadata.metadata_id,
      })
      .then((res) => {
        let audioFilePath = voice_uuid + "/" + res.data.utterance_uuid + ".wav";
        let metaDataPath = voice_uuid + "/metadata.txt";

        // build audio file from Blob
        console.log(res.data);

        let params;

        // send audio file to S3 --- NODE DOING THIS NOW.
        /*
        params = {
          ACL: "private",
          Body: myFile,
          Bucket: S3_BUCKET,
          Key: "training/" + audioFilePath,
        };

        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log("progress: " + Math.round((evt.loaded / evt.total) * 100));
          })
          .send((err) => {
            console.log("SENT file: training/" + audioFilePath);
            if (err) console.log(err);
          });


        // get the utterance info for metadata file

      })
      .catch(() => {});
  };
*/

    const sendToS3 = (params) => {
        myBucket
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                //            setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err) => {
                if (err) console.log(err);
            });
    };

    const removeFromS3 = (params) => {
        myBucket
            .deleteObject(params)
            .on("httpUploadProgress", (evt) => {
                //            setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err) => {
                if (err) console.log(err);
            });
    };

    //audioData contains blob and blobUrl
    const onStop = (a) => {
        console.log(a);
        setAudioData(a);
    };

    const deleteUtterance = (utteranceUUID, confirm) => {
        if (confirm) {
            Swal.fire({
                title: "Remove this Utterance?",
                showCancelButton: true,
                confirmButtonText: "Remove",
                confirmButtonColor: "#CC0000",
                showClass: {
                    popup: "",
                },
                hideClass: {
                    popup: "",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(process.env.REACT_APP_API_SERVER_URL + "/api/utterances/" + utteranceUUID + "/delete", {
                            voice_uuid: voice_uuid,
                        })
                        .then(() => {
                            // refresh metadata on s3
                            let metaDataPath = voice_uuid + "/metadata.txt";
                            getVoiceUtterances().then((arrUtterances) => {
                                console.log("arrUtterances=", arrUtterances);

                                for (const [i, v] of arrUtterances.entries()) {
                                    metaDataText += voice_uuid + "/" + arrUtterances[i].utterance_uuid + ".wav|" + arrUtterances[i].content + "|" + voiceData.speaker_id;
                                    if (i !== arrUtterances.length - 1) {
                                        metaDataText += "\n";
                                    }
                                }

                                console.log("metaDataText=", metaDataText);

                                let metaDataBlob = new Blob([metaDataText], { type: "text/plain;charset=utf-8" });
                                let metaDataFile = new File([metaDataBlob], metaDataPath, {
                                    type: metaDataBlob.type,
                                });

                                // send metadata file to S3
                                sendToS3({
                                    ACL: "private",
                                    Body: metaDataFile,
                                    Bucket: S3_BUCKET,
                                    Key: "training/" + metaDataPath,
                                });

                                getVoiceInfo();
                                setRecordingState("stop");
                                setAudioData(null);
                                metaDataText = "";

                                // remove the WAV for this utterance on S3
                                removeFromS3({
                                    Bucket: S3_BUCKET,
                                    Key: "training/" + voice_uuid + "/" + utteranceUUID + ".wav",
                                });
                            });

                            //                            getVoiceUtterances();
                        })
                        .catch(() => {});
                }
            });
        } else {
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/utterances/" + utteranceUUID + "/delete", {
                    voice_uuid: voice_uuid,
                })
                .then(() => {
                    getVoiceUtterances();
                })
                .catch(() => {});
        }
    };

    async function getVoiceInfo() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/voices/" + voice_uuid);
        const data = await response.json();
        data[0].pct_complete = (parseInt(data[0].ttl_utterances) / parseInt(data[0].minimum_utterances)) * 100;
        document.getElementById("progressBarInner").style.width = data[0].pct_complete + "%";

        if (data[0].pct_complete === 100) {
            document.getElementById("progressBarInner").style.backgroundColor = "#008800";
            console.log("TRUE");
            data[0].voice_ready = true;
        } else {
            console.log("GET");
            data[0].voice_ready = false;
            // get next piece
            getMetadata();
        }

        console.log(data[0]);
        setVoiceData(data[0]);
    }

    let audioContext;
    let playSoundBuffer;
    initAudio();

    async function getUtteranceWAV(utteranceUUID) {
        await axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/get_utterance_wav", {
                voice_uuid: voice_uuid,
                utterance_uuid: utteranceUUID,
            })
            .then((data) => {
                console.log(data.data);
                const blob = new Blob([data.data], { type: "audio/ogg" });
                const url = window.URL.createObjectURL(blob);
                setPlaybackAudio(url);
                //        loadNote(data.data);
                return data;
            });
    }

    function initAudio() {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContext = new AudioContext();
    }

    function loadNote(bytes) {
        audioContext.decodeAudioData(
            bytes,
            function (buffer) {
                playSoundBuffer = buffer;
            },
            function (error) {
                console.error("decodeAudioData error", error);
            }
        );

        playSound();
    }

    function playSound() {
        var source = audioContext.createBufferSource();
        source.buffer = playSoundBuffer; // This is the line that generates the error
        source.connect(audioContext.destination);
        source.start(0);
    }

    async function getVoiceUtterances() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/utterances/" + voice_uuid);
        const data = await response.json();
        setVoiceUtterances(data);
        return data;
    }

    async function getSpecificMetadata(metadataID, utteranceUUID) {
        deleteUtterance(utteranceUUID, false);
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/metadata_specific/" + metadataID);
        const data = await response.json();
        console.log(data);
        let arrPhrase = data[0].content.split("|");
        setMetadata({ content: arrPhrase[0], metadata_id: data[0].metadata_id });
        // set up recording
        setVoiceData({
            ...voiceData,
            status: "new",
            voice_ready: false,
        });
    }

    async function getMetadata() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/metadata/" + voice_uuid);
        const data = await response.json();
        console.log(data);
        playbackSample(data[0].sample_audio_path);
        let arrPhrase = data[0].content.split("|");
        setMetadata({ content: arrPhrase[0], metadata_id: data[0].metadata_id });
    }

    useEffect(() => {
        getVoiceInfo();
        getVoiceUtterances();
    }, []);

    return (
        <Grid container className="mainContainer">
            <Grid item xs={12} id="page-head">
                <ol className="breadcrumb">
                    <li>
                        <a href="/studio">Studio</a>
                    </li>
                    <li>{voiceData.voice_name}</li>
                </ol>
            </Grid>
            <Paper className="mainPanel">
                Speaker ID <b>{voiceData.speaker_id}</b>
                <br />
                <div className="row">
                    <div className="col-sm-5">
                        <div className="infoPill">
                            <i className="fa fa-microphone-alt"></i> {voiceData.ttl_utterances} of {voiceData.minimum_utterances} Samples Recorded
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="progressBar">
                            <div className="progressBarInner" id="progressBarInner" style={{ width: "0%" }}></div>
                        </div>
                    </div>
                </div>
                <div className="formDivider"></div>
                {!voiceData.voice_ready ? (
                    <div className="row formRow">
                        <div className="col-sm-12">
                            <center>
                                <label className="formLabel">Speak this phrase:</label>
                                <div className="sentenceDisplay">{metadata.content}</div>
                                <br />
                                <div className="formDivider"></div>
                                <label className="formLabel">Listen to Sample Audio:</label>
                                <br />
                                <ReactAudioPlayer id="samplePlayer" src="" controls /> <AudioReactRecorder backgroundColor="rgb(255,255,255)" state={recordingState} onStop={onStop} />
                            </center>
                            <div className="row recordingBar">
                                <div className="col-sm-5">
                                    {recordingState === "stop" ? (
                                        <>
                                            {audioData ? (
                                                <>
                                                    <button onClick={start} className="btn btn-big btn-success btnWider">
                                                        Re-Record
                                                    </button>
                                                    &nbsp; &nbsp;
                                                    <button onClick={save} className="btn btn-big btn-primary btnWider">
                                                        Save
                                                    </button>
                                                </>
                                            ) : (
                                                <button onClick={start} className="btn btn-big btn-success btnWider">
                                                    Record
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <button onClick={stop} className="btn btn-big btnWider btn-danger">
                                            Stop
                                        </button>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    {audioData && (
                                        <>
                                            <ReactAudioPlayer src={audioData.url} autoPlay controls />
                                        </>
                                    )}
                                </div>
                                <div className="col-sm-4 right">
                                    <span className="btn btn-big btn-secondary btnWider">
                                        <i className="fa fa-cog"></i>
                                    </span>
                                    &nbsp; &nbsp;
                                    <span className="btn btn-big btn-warning btnWider" onClick={() => skip()}>
                                        SKIP <i className="fa fa-forward"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row formRow">
                        <div className="col-sm-12">
                            {voiceData.status === "training" ? (
                                <>
                                    <center>
                                        <label className="formLabel">Voice is ready to train!</label>
                                        <br />
                                        <br />
                                        <button onClick={() => sendToTraining} className="btn btn-big btnWider btn-success">
                                            Submit for Training
                                        </button>
                                    </center>
                                </>
                            ) : (
                                <>
                                    <center>
                                        <label className="formLabel">Training Pending</label>
                                        <br />
                                        <br />
                                        This voice is now in training. You will receive a notification when training is complete.
                                    </center>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <table width="100%">
                    <tr>
                        <td width="75%">
                            <h5>Saved Recordings</h5>
                        </td>
                        <td width="25%">
                            PLAYBACK CONTROL
                            <ReactAudioPlayer id="playPlayer" src="" autoPlay controls />{" "}
                        </td>
                    </tr>
                </table>
                {voiceUtterances && (
                    <table width="100%">
                        <tbody>
                            {voiceUtterances.map((voiceUtterance) => (
                                <tr key={voiceUtterance.utterance_uuid}>
                                    <td>{voiceUtterance.content.slice(0, 70)}...</td>
                                    <td align="right">
                                        <button className="btn btn-sm btn-secondary" onClick={(e) => getSpecificMetadata(voiceUtterance.metadata_id, voiceUtterance.utterance_uuid)}>
                                            <i className="fa fa-undo"></i>
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-sm btn-success" onClick={(e) => playbackUtterance(voiceUtterance.utterance_uuid)}>
                                            <i className="fa fa-play"></i>
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-sm btn-danger" onClick={(e) => deleteUtterance(voiceUtterance.utterance_uuid, true)}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Paper>
        </Grid>
    );
}
