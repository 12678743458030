import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Grid, Paper, Typography, Box, Menu, MenuItem, Select, InputLabel, Switch, FormGroup, FormControlLabel, Button, ListItemIcon, ListItemText, Slider, Popover } from "@mui/material";
import SpeakerGroupIcon from "@mui/icons-material/SpeakerGroup";
import SpatialAudioIcon from "@mui/icons-material/SpatialAudio";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export function ClipControls({ langColor, languageLabel, sequence, handleEffectButtonClick, updateEffectLabel, handleEffectChange, itemIndex, handleStyleChange }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tempoAnchorEl, setTempoAnchorEl] = React.useState(null);

    let i = 0;

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTempoClose = () => {
        setTempoAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const tempoOpen = Boolean(tempoAnchorEl);

    const closeEffectControl = (e, strEffect) => {
        handleEffectChange(e, itemIndex, strEffect, sequence.sequence_uuid);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTempoClick = (event) => {
        setTempoAnchorEl(event.currentTarget);
    };

    return (
        <>
            <div className="clipControls" data-sequence_uuid={sequence.sequence_uuid}>
                <div className={"languageLabel languageLabel" + langColor}>{languageLabel}</div>
                <Button className="effectLabelText flex-center" aria-describedby={"popover_" + sequence.sequence_uuid} variant="contained" onClick={handleClick} data-sequence_uuid={sequence.sequence_uuid}>
                    Pitch:&nbsp; <b className="effectpitch stronger">{sequence.pitch}%</b>
                </Button>
                <Popover
                    className="effectLabelPopover"
                    id={"popover_" + sequence.sequence_uuid}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Slider className="pitchEffectSlider" style={{ width: "200px" }} data-sequence_uuid={sequence.sequence_uuid} size="small" defaultValue={sequence.pitch} valueLabelDisplay="on" valueLabelFormat={(value) => <div>{value}%</div>} min={-50} max={50} step={1} onMouseUp={(e) => closeEffectControl(e, "pitch")} onChange={(e) => updateEffectLabel(e, "pitch", sequence.sequence_uuid)} />
                </Popover>
                <Button className="effectLabelText flex-center" aria-describedby={"popover_tempo_" + sequence.sequence_uuid} variant="contained" onClick={handleTempoClick} data-sequence_uuid={sequence.sequence_uuid}>
                    Speed:&nbsp; <b className="effecttempo stronger">{sequence.tempo}%</b>
                </Button>
                <Select size="small" defaultValue={sequence.style} className="effectStyleSelect" sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }} onChange={(e) => handleStyleChange(e, itemIndex, sequence.sequence_uuid)}>
                    <MenuItem value={"normal"}>Normal</MenuItem>
                    <MenuItem value={"angry"}>Angry</MenuItem>
                    <MenuItem value={"happy"}>Happy</MenuItem>
                    <MenuItem value={"sad"}>Sad</MenuItem>
                    <MenuItem value={"scared"}>Scared</MenuItem>
                </Select>
                <Popover
                    className="effectLabelPopover"
                    id={"popover_tempo_" + sequence.sequence_uuid}
                    open={tempoOpen}
                    anchorEl={tempoAnchorEl}
                    onClose={handleTempoClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Slider className="pitchEffectSlider" style={{ width: "200px" }} data-sequence_uuid={sequence.sequence_uuid} size="small" defaultValue={sequence.tempo} valueLabelDisplay="on" valueLabelFormat={(value) => <div>{value}%</div>} min={-50} max={50} step={1} onMouseUp={(e) => closeEffectControl(e, "tempo")} onChange={(e) => updateEffectLabel(e, "tempo", sequence.sequence_uuid)} />
                </Popover>
            </div>
        </>
    );
}
