import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
const { v4: uuid_v4 } = require("uuid");

export default function NewVoice() {
  const [voiceName, setVoiceName] = useState("");
  const history = useHistory();
  let voiceUUID = uuid_v4();
  const saveBaseVoice = () => {
    if (voiceName != "") {
      const objUser = JSON.parse(sessionStorage.getItem("user"));
      axios
        .post(process.env.REACT_APP_API_SERVER_URL + "/api/voices/save", {
          voice_name: voiceName,
          voice_uuid: voiceUUID,
          status: "new",
          speaker_id: Math.floor(1000 + Math.random() * 9000),
          user_uuid: objUser.user_uuid,
        })
        .then(() => {
          history.push("/studio/record/" + voiceUUID);
        })
        .catch(() => {});
    } else {
      alert("Voice Name is required.");
    }
  };

  return (
    <div className="page wizardPage">
      <div id="page-head">
        <div id="page-title">
          <div className="row">
            <div className="col-md-9">
              <ol className="breadcrumb"></ol>
            </div>
          </div>
        </div>
      </div>

      <div className="panel panel-success panel-bordered wizardPanel">
        <div className="panel-body">
          <div className="row pageHeader">
            <div className="col-sm-12">
              <h4>New Voice</h4>
              Enter the details of the new voice set below.
              <div className="formDivider"></div>
              <div className="row formRow">
                <div className="col-sm-12">
                  <label className="formLabel">Voice Name</label>
                  <input type="text" value={voiceName} onChange={(e) => setVoiceName(e.target.value)} className="form-control" autoFocus />
                </div>
              </div>
              <label className="formLabel">Voice Source</label> ( Click one to continue )
              <div className="row formRow">
                <div className="col-sm-6">
                  <div className="bigIconButton">
                    <span onClick={() => saveBaseVoice()}>
                      <i className="fa fa-microphone"></i>
                      <br></br>
                      <h6 className="bigIconTitle">Record your voice</h6>
                      Use your microphone to record
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className=" bigIconButton">
                    <i className="fa fa-upload"></i>
                    <br></br>
                    <h6 className="bigIconTitle">Upload audio files</h6>
                    Load pre-recorded clips of your voice
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
