import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { Grid, Paper, Typography, Box, Divider, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Button, Modal, Slider, FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton, Select, MenuItem } from "@mui/material";
import ContentEditable from "react-contenteditable";
import { ClipRow } from "./ClipRow";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Download from "@mui/icons-material/Download";
import { alpha, styled } from "@mui/material/styles";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import io from "socket.io-client";
import { Container, Draggable } from "react-smooth-dnd";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const winkNLP = require("wink-nlp");
const model = require("wink-eng-lite-web-model");
const nlp = winkNLP(model);
const { v4: uuid_v4 } = require("uuid");
let objUser = sessionStorage.getItem("user");

let currentSequences;

try {
    if (objUser && objUser !== {}) objUser = JSON.parse(objUser);
} catch (e) {
    console.log(e);
}

function Sequences() {
    const [sequences, setSequences] = useState(null);
    const { project_uuid } = useParams();
    const [project, setProject] = useState({});
    const [selectedSequenceUUID, setSelectedSequenceUUID] = useState("play");
    const [statusMessage, setStatusMessage] = useState();
    const [open, setOpen] = useState(false);
    const [voices, setVoices] = useState(null);
    const [selectedClipUUID, setSelectedClipUUID] = useState();
    const [selectedClipVoiceID, setSelectedClipVoiceID] = useState();
    const [progressBar, setProgressBar] = useState();
    const [progressBarWidth, setProgressBarWidth] = useState(0);
    const [progressBarPct, setProgressBarPct] = useState(0);
    const [totalClips, setTotalClips] = useState(0);
    const [selectedClips, setSelectedClips] = useState(0);
    const [totalWords, setTotalWords] = useState({});
    const [projectAudioOpen, setProjectAudioOpen] = useState(false);

    let currentlySelctedClipUUID = "";

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        borderRadius: "4px",
        padding: "20px",
        pt: 2,
        px: 4,
        pb: 3,
    };

    const openVoiceModal = (uuid, voice_id) => {
        setSelectedClipUUID(uuid);
        setSelectedClipVoiceID(voice_id);
        getVoices();
        setOpen(true);
    };

    async function getVoices() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/tts_voices/" + objUser.company_uuid + "/" + objUser.user_uuid);
        const data = await response.json();
        console.log(data);
        setVoices(data);
    }

    const playbackSequence = (sequenceUUID, randomKey, outputFormat) => {
        let ele = document.getElementById("playBtn_" + sequenceUUID);
        if (ele.classList.contains("fa-stop")) {
            document.getElementById("playPlayer").src = "";
            ele.classList.remove("fa-stop");
            ele.classList.add("fa-play");
        } else {
            var els = document.getElementsByClassName("playBtnInList");
            Array.prototype.forEach.call(els, function (el) {
                el.classList.remove("fa-stop");
                el.classList.remove("fa-spinner");
                el.classList.add("fa-play");
            });
            setSelectedSequenceUUID(sequenceUUID);
            ele.classList.remove("fa-play");
            ele.classList.add("fa-spinner");
            let sequenceKey = project_uuid + "/" + sequenceUUID + "_" + randomKey + "." + outputFormat;
            console.log(sequenceKey);
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/playback_sequence", {
                    sequence_key: sequenceKey,
                })
                .then((data) => {
                    console.log(data);
                    document.getElementById("playPlayer").src = data.data;
                })
                .catch(() => {});
        }
    };

    async function getProject() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/project/" + project_uuid);
        const data = await response.json();
        const rand = Math.random().toString().substring(2, 8);
        console.log(data);
        setProgressBar(false);
        if (document.getElementById("playProjectAudio")) {
            if (data.audio_joined == 1) {
                document.getElementById("playProjectAudio").src = process.env.REACT_APP_PROCESS_SERVER_URL + "/tts/results/web/projects/" + project_uuid + ".wav?r=" + rand;
                document.getElementById("playProjectAudio").style.display = "inline";
            } else {
                document.getElementById("playProjectAudio").style.display = "none";
            }
        }
        setProject(data);
    }

    const downloadSequence = (sequenceUUID, randomKey, outputFormat) => {
        console.log(project_uuid + "/" + sequenceUUID + "_" + randomKey + "." + outputFormat);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/playback_sequence", {
                sequence_key: project_uuid + "/" + sequenceUUID + "_" + randomKey + "." + outputFormat,
            })
            .then((data) => {
                axios({ url: data.data, method: "GET", responseType: "blob" }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", sequenceUUID + "_" + randomKey + ".wav");
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(() => {});
    };

    const deleteSequence = (id) => {
        Swal.fire({
            title: "Remove this Clip?",
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#CC0000",
            showClass: {
                popup: "",
            },
            hideClass: {
                popup: "",
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/" + id + "/delete", {
                        id: id,
                    })
                    .then(() => {
                        console.log("getting data...");
                        getData();
                    })
                    .catch(() => {});
            }
        });
    };

    const saveSequence = () => {};

    async function addSequence(sequenceText = "", clipOrder = 0) {
        let sequenceUUID = uuid_v4();
        let newClipVoiceID = process.env.REACT_APP_DEFAULT_VOICE_ID;

        if (clipOrder == 0) {
            if (sequences && sequences[0]) {
                // get clip from sequences (last row)
                let lastClip = sequences[sequences.length - 1];
                // set voice ID to previous clip's voice ID
                newClipVoiceID = lastClip.voice_id;
                // increment clip order for new clip
                clipOrder = lastClip.clip_order + 1;
            } else {
                clipOrder = 1;
            }
        }

        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/save", {
                sequence_uuid: "new-" + sequenceUUID,
                project_uuid: project_uuid,
                sequence_text: sequenceText,
                voice_id: newClipVoiceID,
                language_code: "en-CUSTOM",
                output_format: "mp3",
                word_count: 0,
                clip_order: clipOrder,
                style: "normal",
            })
            .then((res) => {
                console.log("sequence saved... ", res);
                getData();
            })
            .catch(() => {});
    }

    async function getData() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/" + project_uuid);
        const data = await response.json();
        console.log("len", data.length);
        setTotalClips(data.length);
        setSequences(data);
        currentSequences = data;
        countAllWordsInProject();
        console.log(data);
    }

    const closeVoiceModal = () => {
        setOpen(false);
        getData();
    };

    const handleCanPlay = () => {
        //        console.log("can play");
        document.getElementById("playBtn_" + selectedSequenceUUID).classList.remove("fa-play");
        document.getElementById("playBtn_" + selectedSequenceUUID).classList.remove("fa-spinner");
        document.getElementById("playBtn_" + selectedSequenceUUID).classList.add("fa-stop");
    };

    const handleEnded = () => {
        //        console.log("ended");
        var els = document.getElementsByClassName("playBtnInList");
        Array.prototype.forEach.call(els, function (el) {
            el.classList.remove("fa-stop");
            el.classList.remove("fa-spinner");
            el.classList.add("fa-play");
        });
    };

    const disableActions = () => {
        document.getElementById("addButton").disabled = true;
        document.getElementById("joinButton").disabled = true;
        const inputs = document.querySelectorAll("button.btn");
        inputs.forEach((input) => (input.disabled = true));
    };

    const enableActions = () => {
        document.getElementById("addButton").disabled = false;
        document.getElementById("joinButton").disabled = false;
        const inputs = document.querySelectorAll("button.btn");
        inputs.forEach((input) => (input.disabled = false));
    };

    function replace_multiple(str, mapObj) {
        let regex = new RegExp("\\b(?:" + Object.keys(mapObj).join("|") + ")\\b", "gi");
        str = str.replace(regex, (matched) => mapObj[matched]);
        return str;
    }

    async function processSpeechAdaptations(s) {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/speech_adaptations_by_length/");
        const arrAdaptations = await response.json();
        let mapObj = {};
        Array.prototype.forEach.call(arrAdaptations, function (t) {
            mapObj[t.original_word] = t.adapted_word;
        });
        let newText = replace_multiple(s, mapObj);
        return newText;
    }

    // infer one clip to hear how it sounds
    async function inferSequence(uuid, playbackDisabled = false) {
        console.log("inferring as EXPRESSS.......");
        document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + uuid + '"] .statusLabel')[0].innerHTML = "Preparing...";
        document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + uuid + '"] .statusLabel')[0].style.border = "1px solid rgb(236,197,113)";
        let objReturn;
        disableActions();
        console.log("uuid=" + uuid);
        let ele = document.getElementById("playBtn_" + uuid);
        if (!playbackDisabled) {
            ele.classList.remove("fa-play");
            ele.classList.add("fa-spinner");
        }
        let e = document.querySelectorAll('.clipTextInput[data-sequence_uuid="' + uuid + '"]');
        console.log(e[0].innerHTML);
        let sequenceText = e[0].innerHTML;
        let currentSequence = sequences.find((item) => item.sequence_uuid === uuid);

        console.log("currentSequence", currentSequence);

        // process adaptations
        let adaptedText = await processSpeechAdaptations(sequenceText);

        // infer just this clip
        if (sequenceText !== "") {
            let objPayload = {
                sequence_text: sequenceText,
                adapted_sequence_text: adaptedText,
                sequence_uuid: uuid,
                project_uuid: project_uuid,
                voice_id: currentSequence.voice_id,
                voice_uuid: currentSequence.voice_uuid,
                default_seed: currentSequence.seed,
                output_format: "wav",
                pitch: currentSequence.pitch,
                tempo: currentSequence.tempo,
                style: currentSequence.style,
                ar_path: currentSequence.ar_path,
            };
            console.log(objPayload);
            await axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/synthesize_speech_express", objPayload)
                .then((res) => {
                    enableActions();
                    // playback this sequence
                    console.log("result from save", res);
                    if (!playbackDisabled) {
                        playbackSequence(uuid, res.data.random_key, "mp3");
                    }
                    // set random key for this sequence so we can DL right away if needed
                    currentSequence.random_key = res.data.random_key;
                    currentSequence.duration = res.data.duration;
                    currentSequence.inferred = 1;
                    currentSequence.tts_platform = "Express";
                    currentSequence.status = "complete";
                    console.log(currentSequence);
                    objReturn = res;
                    document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + currentSequence.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #eee";
                })
                .catch(() => {});
        } else {
            alert("Sequence Text is required.");
        }
        return objReturn;
    }

    // infer one clip to hear how it sounds
    async function inferToucanSequence(uuid, playbackDisabled = false) {
        document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + uuid + '"] .statusLabel')[0].innerHTML = "Preparing...";
        document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + uuid + '"] .statusLabel')[0].style.border = "1px solid rgb(236,197,113)";
        let objReturn;
        disableActions();
        console.log("uuid=" + uuid);
        let ele = document.getElementById("playBtn_" + uuid);
        if (!playbackDisabled) {
            ele.classList.remove("fa-play");
            ele.classList.add("fa-spinner");
        }
        let e = document.querySelectorAll('.clipTextInput[data-sequence_uuid="' + uuid + '"]');
        console.log(e[0].innerHTML);
        let sequenceText = e[0].innerHTML;
        let currentSequence = sequences.find((item) => item.sequence_uuid === uuid);

        console.log("currentSequence", currentSequence);

        // process adaptations
        let adaptedText = await processSpeechAdaptations(sequenceText);

        // infer just this clip
        if (sequenceText !== "") {
            let objPayload = {
                sequence_text: sequenceText,
                adapted_sequence_text: adaptedText,
                sequence_uuid: uuid,
                project_uuid: project_uuid,
                voice_id: currentSequence.voice_id,
                voice_uuid: currentSequence.voice_uuid,
                default_seed: currentSequence.seed,
                output_format: "wav",
                pitch: currentSequence.pitch,
                tempo: currentSequence.tempo,
                style: currentSequence.style,
                ar_path: currentSequence.ar_path,
            };
            console.log(objPayload);
            await axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/synthesize_speech_toucan", objPayload)
                .then((res) => {
                    enableActions();
                    // playback this sequence
                    console.log("result from save", res);
                    if (!playbackDisabled) {
                        playbackSequence(uuid, res.data.random_key, "mp3");
                    }
                    // set random key for this sequence so we can DL right away if needed
                    currentSequence.random_key = res.data.random_key;
                    currentSequence.duration = res.data.duration;
                    currentSequence.tts_platform = "Toucan";
                    currentSequence.inferred = 1;
                    currentSequence.status = "complete";
                    console.log(currentSequence);
                    objReturn = res;
                    document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + currentSequence.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #eee";
                    return objReturn;
                })
                .catch(() => {});
        } else {
            alert("Sequence Text is required.");
        }
    }

    async function handleClipTextPaste(event) {
        event.preventDefault();
        let paste = (event.clipboardData || window.clipboardData).getData("text");
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    }

    const splitSentences = (str = "", size) => {
        const text = str.trim();
        const doc = nlp.readDoc(text);
        return doc.sentences().out();
    };

    const splitString = (str = "", size) => {
        const patternString = String.raw`\S.{1,${size - 2}}\S(?= |$)`;
        const regex = new RegExp(patternString, "g");
        return str.split(/[,.]/);
    };

    const handleProjectAudioClose = () => {
        setProjectAudioOpen(false);
    };

    // join several clips into a project-based WAV file
    async function joinClips() {
        let arrClips = selectedChoices;
        console.log(arrClips);
        setProgressBarPct(0);
        let sequences = [];
        let c = 0;

        setProjectAudioOpen(true);
        setProgressBar(true);

        let clipElements = [];

        // get all clipRows
        let clipRows = document.querySelectorAll(".clipTextInput");

        for (const clipRow of clipRows) {
            if (arrClips.includes(clipRow.dataset.sequence_uuid)) {
                clipElements.push(clipRow);
            }
        }

        for (const clipElement of clipElements) {
            console.log("saving clip blah for " + c);
            let currentStep = c + 1;
            setStatusMessage("Saving Clip " + (c + 1) + " of " + clipElements.length + "...");
            // infer the sequence - if the sequence is not inferred
            if (clipElements[c].dataset.inferred == 0) {
                let inferred;
                if (clipElements[c].dataset.tts_platform == "Express") {
                    inferred = await inferSequence(clipElements[c].dataset.sequence_uuid, true);
                } else {
                    inferred = await inferToucanSequence(clipElements[c].dataset.sequence_uuid, true);
                }
                console.log("INFERRED:", inferred);
                sequences.push({ uuid: inferred.data.sequence_uuid, random_key: inferred.data.random_key });
            } else {
                // take what is there already
                sequences.push({ uuid: clipElements[c].dataset.sequence_uuid, random_key: clipElements[c].dataset.random_key });
            }
            let pct = (currentStep / clipElements.length) * 100;
            setProgressBarPct(pct);
            setProgressBarWidth(pct + "%");
            c++;
        }

        console.log(sequences);

        let objPayload = {
            project_uuid: project_uuid,
            num_sequences: sequences.length,
            sequences: sequences,
        };
        console.log(objPayload);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/join_clips", objPayload)
            .then((res) => {
                setStatusMessage("");
                getProject();
            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    const handleClipTextChange = (e) => {
        //console.log(e.target.innerHTML);
    };

    const hideSequenceControlBars = () => {
        const allSequenceControlBars = document.getElementsByClassName("sequenceControlBar");
        for (let i = 0; i < allSequenceControlBars.length; i++) {
            if (allSequenceControlBars[i].style.display == "inline") {
                allSequenceControlBars[i].style.display = "none";
            }
        }
    };

    const dimClipRowBorders = (uuid) => {
        const allClipRows = document.getElementsByClassName("clipRow");
        for (let i = 0; i < allClipRows.length; i++) {
            if (allClipRows[i].getAttribute("data-sequence_uuid") != uuid) {
                allClipRows[i].classList.remove("activeClipRow");
            }
        }
    };

    const highlightClipRow = (uuid) => {
        dimClipRowBorders(uuid);
        document.querySelectorAll('.clipRow[data-sequence_uuid="' + uuid + '"]')[0].classList.add("activeClipRow");
    };

    const handleEffectButtonClick = (e, uuid, strEffect) => {
        highlightClipRow(uuid);
    };

    const handleClipRowClick = (e, uuid) => {
        handleClipTextClick(e, uuid);
    };

    const handleClipRowBlur = (e, uuid) => {
        document.querySelectorAll('.clipRow[data-sequence_uuid="' + uuid + '"]')[0].classList.remove("activeClipRow");
        document.querySelectorAll('.clipControls[data-sequence_uuid="' + uuid + '"]')[0].style.height = "0px";
        document.querySelectorAll('.clipControls[data-sequence_uuid="' + uuid + '"]')[0].style.display = "none";
        document.querySelectorAll('.minEffectLabels[data-sequence_uuid="' + uuid + '"]')[0].style.display = "inline";
    };

    const showClipControls = (uuid) => {
        //        console.log('.clipControls[data-sequence_uuid="' + uuid + '"]');
        //        console.log(document.querySelector('.clipControls[data-sequence_uuid="' + uuid + '"]'));
        document.querySelectorAll('.clipControls[data-sequence_uuid="' + uuid + '"]')[0].style.height = "30px";
        document.querySelectorAll('.clipControls[data-sequence_uuid="' + uuid + '"]')[0].style.display = "inline";
        document.querySelectorAll('.minEffectLabels[data-sequence_uuid="' + uuid + '"]')[0].style.display = "none";
    };

    const handleClipTextClick = (e, uuid) => {
        if (currentlySelctedClipUUID) {
            document.querySelectorAll('.clipControls[data-sequence_uuid="' + currentlySelctedClipUUID + '"]')[0].style.height = "0px";
            document.querySelectorAll('.clipControls[data-sequence_uuid="' + currentlySelctedClipUUID + '"]')[0].style.display = "none";
            document.querySelectorAll('.minEffectLabels[data-sequence_uuid="' + currentlySelctedClipUUID + '"]')[0].style.display = "inline";
        }
        currentlySelctedClipUUID = uuid;
        highlightClipRow(uuid);
        showClipControls(uuid);
    };

    function roundValue(num, decimalPlaces = 0) {
        num = Math.round(num + "e" + decimalPlaces);
        return Number(num + "e" + -decimalPlaces);
    }

    // change an effect on the current clip
    const handleEffectChange = (e, itemIndex, strEffect, uuid) => {
        console.log("handleEffectChange", e);
        let effectValue = e.target.innerText.replace("%", "");
        effectValue = parseInt(effectValue);
        console.log("effectValue", effectValue);
        sequences[itemIndex][strEffect] = effectValue;
        updateCurrentSequence(uuid, { [strEffect]: effectValue });
    };

    // update current clip in db
    const updateCurrentSequence = (uuid, objUpdate, cb = null) => {
        // reset inference status
        objUpdate.inferred = 0;
        let itemIndex = document.querySelectorAll('.clipRow[data-sequence_uuid="' + uuid + '"]')[0].dataset.item_index;
        sequences[itemIndex]["inferred"] = 0;
        console.log("objUpdate=", objUpdate);
        let objData = {
            sequence_uuid: uuid,
        };
        for (const [key, value] of Object.entries(objUpdate)) {
            objData[key] = value;
        }

        console.log(objData);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/save", objData)
            .then((res) => {
                //        console.log("sequence saved... ", res);
                if (cb) {
                    cb();
                }
            })
            .catch(() => {});
    };

    const handleStyleChange = (e, itemIndex, uuid) => {
        updateCurrentSequence(uuid, { style: e.target.value });
        sequences[itemIndex]["style"] = e.target.value;
    };

    const handleClipTextBlur = (e, uuid, itemIndex) => {
        countAllWordsInProject();
        document.querySelectorAll('.clipRow[data-sequence_uuid="' + uuid + '"]')[0].classList.remove("activeClipRow");
        document.querySelectorAll('.clipControls[data-sequence_uuid="' + uuid + '"]')[0].style.height = "0px";
        document.querySelectorAll('.clipControls[data-sequence_uuid="' + uuid + '"]')[0].style.display = "none";
        document.querySelectorAll('.minEffectLabels[data-sequence_uuid="' + uuid + '"]')[0].style.display = "inline";
        updateCurrentSequence(uuid, { sequence_text: e.target.innerHTML });
    };

    const setVoiceAndUpdateClip = (uuid, voice) => {
        console.log("voice change to", voice);

        updateCurrentSequence(uuid, { voice_id: voice.id, voice_uuid: voice.voice_uuid, language_code: "en-CUSTOM", output_format: "mp3", default_seed: voice.default_seed }, closeVoiceModal);
    };

    function DelimiterLabel() {
        return (
            <>
                <span className="projectOptionsSwitchLabel">Split text in clips</span>
                <br />
                <span className="projectOptionsSwitchSublabel">Use "|" to split inferences within a clip</span>
            </>
        );
    }

    const countAllWordsInProject = (e) => {
        var els = document.getElementsByClassName("clipTextInput");
        let allWords = 0;
        Array.prototype.forEach.call(els, function (el, i) {
            countWords(el.innerHTML).then((w) => {
                allWords = allWords + parseInt(w);
                if (i === els.length - 1) {
                    console.log(allWords);
                    setTotalWords({ total: allWords, time: allWords / 40 });
                }
            });
        });
    };

    const moveCard = (id, index) => {
        const { sequences } = sequences;
        const sourceCard = sequences.find((sequence) => sequence.sequence_uuid === id);
        const sortCards = sequences.filter((sequence) => sequence.sequence_uuid !== id);

        sortCards.splice(index, 0, sourceCard);
        setSequences(sortCards);
        currentSequences = sortCards;
    };

    async function countWords(s) {
        if (s !== "") {
            s = s.replace(/\n/g, " ");
            s = s.replace(/(^\s*)|(\s*$)/gi, "");
            s = s.replace(/[ ]{2,}/gi, " ");
            let c = s.split(" ").length;
            return c;
        } else {
            return 0;
        }
    }

    const handleDelimiterChange = (e) => {};

    const updateEffectLabel = (e, strEffect, uuid) => {
        document.querySelectorAll('.effectLabelText[data-sequence_uuid="' + uuid + '"] .effect' + strEffect)[0].innerHTML = e.target.value + "%";
    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#28a745",
            "&:hover": {
                backgroundColor: "#28a745",
            },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#28a745",
        },
    }));

    const updateClipOrder = (updatedSequences) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/reorder", {
                sequences: updatedSequences,
            })
            .then((data) => {
                console.log(data);
            })
            .catch(() => {});
    };

    const [selectedChoices, setSelectedChoices] = useState([]);

    const deselectAllClips = () => {
        setSelectedChoices([]);
    };

    const handleCheckboxChange = (val) => {
        let flag = false;
        for (const i in selectedChoices) {
            if (selectedChoices[i] === val) {
                flag = true;
                break;
            }
        }
        if (flag) {
            setSelectedChoices(selectedChoices.filter((number) => number !== val));
        } else {
            setSelectedChoices(selectedChoices.concat(val));
        }
    };

    const onClipRowDrop = (e) => {
        console.log(e, sequences.length);
        if (e.addedIndex == sequences.length) {
            e.addedIndex = sequences.length - 1;
        }
        let dragIndex = e.removedIndex;
        let hoverIndex = e.addedIndex;
        const dragItem = sequences[dragIndex];
        const hoverItem = sequences[hoverIndex];
        console.log("set " + e.removedIndex + " to " + e.addedIndex);
        const updatedSequences = [...sequences];
        updatedSequences[dragIndex] = hoverItem;
        updatedSequences[hoverIndex] = dragItem;
        //        setSequences(sequences);
        // console.log(updatedSequences);
        updateClipOrder(updatedSequences);
        setSequences(updatedSequences);
        currentSequences = updatedSequences;
    };

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getData();
        getProject();
        const socket = io(`https://api.vectorvoice.com:4003`);
        // tortoise signals
        socket.on("sequence_updated", sequenceUpdated);
        // toucan signals
        socket.on("sequence_updated_toucan", sequenceUpdatedToucan);
    }, []);

    let curSequenceProgress = 0;

    async function sequenceUpdatedToucan(m) {
        console.log("sequenceUpdatedToucan");
        console.log(m);
    }

    async function sequenceUpdated(m) {
        console.log(m);
        console.log(currentSequences);
        let currentSequence = currentSequences.find((item) => item.sequence_uuid === m.sequence_uuid);
        console.log("currentSequence", currentSequence);

        let e = document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"]')[0];
        let pct, rem;
        console.log("Event: " + m.event_type);
        switch (m.event_type) {
            case "samples":
                curSequenceProgress = m.progress;
                console.log(m.progress + "% 100%");
                rem = 100 - m.progress;
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = "1/3 Reading...";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "linear-gradient(90deg, #99f0ae " + m.progress + "%, #eee 0)";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #e3b096";
                break;
            case "candidates":
                console.log(m.progress + "% 100%");
                rem = 100 - m.progress;
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = "2/3 Learning...";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "linear-gradient(90deg, #99f0ae " + m.progress + "%, #eee 0)";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #e3b096";
                break;
            case "transforming":
                curSequenceProgress = m.progress;
                console.log(m.progress + "% 100%");
                rem = 100 - m.progress;
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = "3/3 Saving...";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #e3b096";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "linear-gradient(90deg, #99f0ae " + m.progress + "%, #eee 0)";
                break;
            case "complete":
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = Math.round(m.duration * 100) / 100 + " sec";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "#FFF";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #99f0ae";
                document.querySelectorAll('.clipRowActionButton[data-sequence_uuid="' + m.sequence_uuid + '"]')[0].classList.add("clipRowPlayInferred");
                document.querySelectorAll('.spinnerContainer[data-sequence_uuid="' + m.sequence_uuid + '"')[0].style.display = "none";

                let objData2 = {
                    sequence_uuid: m.sequence_uuid,
                    inferred: 1,
                };

                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/save", objData2)
                    .then((res) => {
                        console.log("UPDATED seq " + m.sequence_uuid + " to inferred = 1");
                    })
                    .catch(() => {});

                console.log("m.sequence_uuid=" + m.sequence_uuid);
                console.log("m.sequence_uuid=" + m.random_key);
                currentSequence.inferred = 1;
                currentSequence.status = "complete";
                currentSequence.random_key = m.random_key;
                playbackSequence(m.sequence_uuid, m.random_key, "mp3");
                enableActions();

                break;
            case "completed":
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = Math.round(m.duration * 100) / 100 + " sec";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "#FFF";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #99f0ae";
                document.querySelectorAll('.clipRowActionButton[data-sequence_uuid="' + m.sequence_uuid + '"]')[0].classList.add("clipRowPlayInferred");
                document.querySelectorAll('.spinnerContainer[data-sequence_uuid="' + m.sequence_uuid + '"')[0].style.display = "none";

                let objData = {
                    sequence_uuid: m.sequence_uuid,
                    inferred: 1,
                };

                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/sequences/save", objData)
                    .then((res) => {
                        console.log("UPDATED seq " + m.sequence_uuid + " to inferred = 1");
                    })
                    .catch(() => {});

                console.log("m.sequence_uuid=" + m.sequence_uuid);
                console.log("m.sequence_uuid=" + m.random_key);
                currentSequence.inferred = 1;
                currentSequence.status = "complete";
                currentSequence.random_key = m.random_key;
                playbackSequence(m.sequence_uuid, m.random_key, "mp3");
                break;
            case "generating speech":
                pct = m.progress * 100;
                rem = 100 - pct;
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = "2/3 Processing...";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "linear-gradient(90deg, #e3b096 " + pct + "%, #FFF " + rem + "%)";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #e3b096";
                break;
            case "saving speech":
                pct = m.progress * 100;
                rem = 100 - pct;
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].innerHTML = "3/3: Saving speech...";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.background = "linear-gradient(90deg, #d6e396 " + pct + "%, #FFF " + rem + "%)";
                document.querySelectorAll('.sequenceStatus[data-sequence_uuid="' + m.sequence_uuid + '"] .statusLabel')[0].style.border = "1px solid #d6e396";
                break;
        }
    }

    return (
        <Grid container className="mainContainer">
            <Grid container item xs={12} id="page-head">
                <Grid item xs={7}>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/projects/">Projects</Link>
                        </li>
                        <li>{project.project_name || "..."}</li>
                    </ol>
                </Grid>
                <Grid item xs={5}></Grid>
            </Grid>

            <Box className="projectOptionsBarContainer">
                <Grid container className="projectOptionsBar">
                    <Grid className="projectOptionsColumn">
                        <FormGroup>
                            <Grid container>
                                <Grid item>
                                    <Select size="small" defaultValue="auto" className="delimiterStyleSelect" sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }} onChange={(e) => handleDelimiterChange(e)}>
                                        <MenuItem value={"auto"}>Auto Clips</MenuItem>
                                        <MenuItem value={"manual"}>Manual Clips</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item className="vertCenter"></Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid className="projectOptionsColumn noPadding">
                        {totalClips > 0 ? (
                            <>
                                <button disabled={true} id="joinButton" onClick={(e) => joinClips()} className="btn-sm btn btn-white tightButton">
                                    <Download /> <div className="addButtonText">Download All Clips</div>
                                </button>
                            </>
                        ) : (
                            <>
                                <button disabled={true} className="btn-sm btn addButton tightButton">
                                    <Download /> <div className="addButtonText">Download All Clips</div>
                                </button>
                            </>
                        )}
                    </Grid>
                    <Grid item className="vertCenter">
                        &nbsp;
                        <TextSnippetOutlinedIcon />
                        &nbsp;<b>{totalWords.total}</b>&nbsp;words&nbsp;
                    </Grid>
                    <Grid className="projectOptionsColumn noPadding joinClipsColumn">
                        {selectedChoices.length > 0 ? (
                            <>
                                <button className="btn-sm btn btn-default tightButton" onClick={(e) => deselectAllClips()}>
                                    Deselect All
                                </button>
                                <button id="joinButton" onClick={(e) => joinClips()} className="btn-sm btn btn-success tightButton">
                                    <ControlPointIcon /> <div className="addButtonText">Join Selected Clips</div>
                                </button>
                            </>
                        ) : (
                            <>
                                <button disabled={true} className="btn-sm btn addButton tightButton">
                                    <ControlPointIcon /> <div className="addButtonText">Join Selected Clips</div>
                                </button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <br />
                    <Grid container className="clipTable">
                        <Container onDrop={onClipRowDrop} dragHandleSelector=".clipRowDraggerHolder" dragClass="styleWhileDragging">
                            {sequences && sequences.length > 0 ? (
                                <>
                                    {sequences.map((sequence, i) => (
                                        <Draggable key={sequence.sequence_uuid}>
                                            <ClipRow
                                                sequence={sequence}
                                                handleEffectButtonClick={handleEffectButtonClick}
                                                updateEffectLabel={updateEffectLabel}
                                                handleEffectChange={handleEffectChange}
                                                itemIndex={i}
                                                handleStyleChange={handleStyleChange}
                                                i={i}
                                                handleClipRowClick={handleClipRowClick}
                                                openVoiceModal={openVoiceModal}
                                                handleClipTextClick={handleClipTextClick}
                                                handleClipTextPaste={handleClipTextPaste}
                                                handleClipTextBlur={handleClipTextBlur}
                                                handleClipTextChange={handleClipTextChange}
                                                playbackSequence={playbackSequence}
                                                inferSequence={inferSequence}
                                                inferToucanSequence={inferToucanSequence}
                                                downloadSequence={downloadSequence}
                                                deleteSequence={deleteSequence}
                                                moveCard={moveCard}
                                                setSelectedChoices={setSelectedChoices}
                                                selectedChoices={selectedChoices}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />
                                        </Draggable>
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                            <ReactAudioPlayer id="playPlayer" onCanPlay={handleCanPlay} onEnded={handleEnded} src="" autoPlay controls style={{ display: "none" }} />
                        </Container>
                    </Grid>
                    <Box>
                        <center>
                            <br />
                            <button id="addButton" onClick={(e) => addSequence()} className="btn-sm btn btn-success addButton">
                                <ControlPointIcon /> <div className="addButtonText">Add a Clip</div>
                            </button>

                            <br />
                        </center>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={projectAudioOpen} onClose={handleProjectAudioClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <Box sx={{ ...modalStyle, width: 400 }}>
                    <div>
                        <Grid container>
                            <Grid item xs={9}>
                                <h3>Project Audio</h3>
                            </Grid>
                            <Grid item className="right" xs={3}>
                                <Button size="small" className="modalCloseButton" onClick={(e) => setProjectAudioOpen(false)}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>

                        {statusMessage && (
                            <>
                                <div className="statusMessage">
                                    {statusMessage}{" "}
                                    {progressBar && (
                                        <>
                                            <div className="progressBarOuter">
                                                <div className="progressBarInner" style={{ width: progressBarWidth, textAlign: "left", transition: "all 0.3s" }}></div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        <br />
                        <div className="projectAudioBox">
                            <ReactAudioPlayer id="playProjectAudio" src="" controls style={{ display: "inline", width: "100%" }} />
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={open} onClose={closeVoiceModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="voiceModalHolder">
                <Box className="voiceModal">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Select a Voice
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Box xs={12} className="mainPanel">
                            <Grid container spacing={2}>
                                {voices && (
                                    <>
                                        {voices.map((voice) => (
                                            <>
                                                {voice.id == selectedClipVoiceID ? (
                                                    <>
                                                        <Paper xs={12} className={"voiceModalItem voiceModalItemActive " + (voice.user_uuid ? "customVoiceModalItem" : "")} key={voice.id} onClick={() => setVoiceAndUpdateClip(selectedClipUUID, voice)}>
                                                            {voice.user_uuid == objUser.user_uuid ? (
                                                                <>
                                                                    <div className="userVoiceIcon">
                                                                        <RecordVoiceOverIcon></RecordVoiceOverIcon>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                                <div className="avatarInTileCropper">
                                                                    <img src={"/assets/img/avatars/" + voice.avatar + ".jpg"} alt={voice.voice_alias} className="avatarInSelect" />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <h4>{voice.voice_alias}</h4>
                                                            {voice.readable_label}, {voice.voice_gender}
                                                            <br />
                                                            <h4>{voice.model_name}</h4>
                                                        </Paper>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Paper xs={12} className={"voiceModalItem " + (voice.user_uuid ? "customVoiceModalItem" : "")} key={voice.id} onClick={() => setVoiceAndUpdateClip(selectedClipUUID, voice)}>
                                                            {voice.user_uuid == objUser.user_uuid ? (
                                                                <>
                                                                    <div className="userVoiceIcon">
                                                                        <RecordVoiceOverIcon></RecordVoiceOverIcon>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                                <div className="avatarInTileCropper">
                                                                    <img src={"/assets/img/avatars/" + voice.avatar + ".jpg"} alt={voice.voice_alias} className="avatarInSelect" />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <h4>{voice.voice_alias}</h4>
                                                            {voice.readable_label}, {voice.voice_gender}
                                                            <br />
                                                            <h4>{voice.model_name}</h4>
                                                        </Paper>
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    );
}

export default Sequences;
