import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ProjectMenu from "../Common/ProjectMenu";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

export default function Projects() {
    const [projects, setProjects] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [projectUUID, setProjectUUID] = useState("new");
    const [wordTotal, setWordTotal] = useState(null);
    const [projectDialogTitle, setProjectDialogTitle] = useState(null);
    const [projectDialogOpen, setProjectDialogOpen] = useState(false);

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const updateProjectName = (e) => {
        setProjectName(e.target.value);
    };

    const handleProjectDialogOpen = (id, name = "") => {
        if (id === "new") {
            setProjectDialogTitle("Add New Project");
        } else {
            setProjectDialogTitle("Edit Project Details");
        }
        setProjectUUID(id);
        setProjectName(name);
        setProjectDialogOpen(true);
    };

    const saveBaseProject = () => {
        if (projectName != "") {
            const objUser = JSON.parse(sessionStorage.getItem("user"));
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/projects/save", {
                    project_name: projectName,
                    project_uuid: projectUUID,
                    user_uuid: objUser.user_uuid,
                })
                .then(() => {
                    handleClose();
                    getData();
                })
                .catch(() => {});
        } else {
            alert("Project Name is required.");
        }
    };

    const handleClose = () => {
        setProjectDialogOpen(false);
    };

    const deleteProject = (id) => {
        Swal.fire({
            title: "Remove this Project?",
            showCancelButton: true,
            confirmButtonText: "Remove",
            confirmButtonColor: "#CC0000",
            showClass: {
                popup: "",
            },
            hideClass: {
                popup: "",
            },
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/projects/" + id + "/delete", {
                        project_uuid: id,
                    })
                    .then(() => {
                        getData();
                    })
                    .catch(() => {});
            }
        });
    };

    async function getData() {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/projects", {
                user_uuid: objUser.user_uuid,
            })
            .then((response) => {
                setProjects(response.data.projectList);
            })
            .catch(() => {});
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>Projects</li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="alignRight topMenuRight">
                            <span onClick={() => handleProjectDialogOpen("new", "")} className="btn-sm btn btn-success addButton">
                                <ControlPointIcon /> Add Project
                            </span>
                        </div>
                    </Grid>
                </Grid>

                <Box item xs={12} className="mainPanel">
                    <Grid container spacing={2}>
                        {projects && (
                            <>
                                {projects.map((project) => (
                                    <Paper className="projectBox">
                                        <Box key={project.project_uuid} xs={9} className="projectBoxInfo">
                                            <Grid container spacing={0}>
                                                <Grid item xs={11}>
                                                    <Link to={"/project/" + project.project_uuid + "/sequences/"}>
                                                        <h4>{project.project_name}</h4>
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={1} style={{ textAlign: "right !important", position: "absolute", right: "20px", top: "20px" }}>
                                                    <ProjectMenu project={project} handleProjectDialogOpen={handleProjectDialogOpen} deleteProject={deleteProject} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider />
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1, bgcolor: "background.default" }}>
                                            {moment(project.created_at).format("MM/DD/YYYY hh:mm:ssA")}
                                        </Stack>
                                    </Paper>
                                ))}
                            </>
                        )}
                    </Grid>
                </Box>
            </Grid>

            <Dialog open={projectDialogOpen} onClose={handleClose}>
                <DialogTitle>{projectDialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Project Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={updateProjectName}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") saveBaseProject();
                        }}
                        value={projectName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="cancelButton" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={saveBaseProject}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
