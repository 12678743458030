import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { useParams } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { Grid, Paper, Typography, Box, Divider, TableContainer, Table, TableRow, TableCell, TableHead, TableBody } from "@mui/material";

export default function SpeechAdaptations() {
  const [adaptations, setAdaptations] = useState(null);

  async function getData() {
    const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/speech_adaptations/");
    const data = await response.json();
    setAdaptations(data);
    console.log(data);
  }

  const deleteAdaptation = (id) => {
    Swal.fire({
      title: "Remove this Speech Adaptation?",
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: "#CC0000",
      showClass: {
        popup: "",
      },
      hideClass: {
        popup: "",
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post(process.env.REACT_APP_API_SERVER_URL + "/api/speech_adaptation/" + id + "/delete", {
            id: id,
          })
          .then(() => {
            console.log("getting data...");
            getData();
          })
          .catch(() => {});
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container className="mainContainer">
      <Grid container xs={12} id="page-head">
        <Grid item xs={7}>
          <ol className="breadcrumb">
            <li>Settings</li>
            <li>Speech Adaptations</li>
          </ol>
        </Grid>
        <Grid item xs={5}>
          <div className="alignRight topMenuRight">
            <a href={"/settings/speech_adaptation/new"} className="btn-sm btn btn-success addButton">
              <i className="fa-solid fa-square-poll-vertical"></i> <div className="addButtonText">Add Speech Adaptation</div>
            </a>
          </div>
        </Grid>
      </Grid>
      <Box xs={12} className="mainPanel">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider />
            {adaptations && adaptations.length > 0 ? (
              <>
                <TableContainer component={Paper} style={{ padding: "25px" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Original Text</TableCell>
                        <TableCell>Adapted Text</TableCell>
                        <TableCell>Case Sensitive?</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {adaptations.map((adaptation) => (
                        <TableRow key={adaptation.id}>
                          <TableCell component="th" scope="row" style={{ border: "none !important" }}>
                            <a href={"/settings/speech_adaptation/" + adaptation.id}>
                              <i style={{ textOverflow: "ellipsis" }}>{adaptation.original_word}</i>
                            </a>
                          </TableCell>
                          <TableCell>
                            <div className="voiceAvatarColumn">{adaptation.adapted_word}</div>
                          </TableCell>
                          <TableCell>
                            <div className="voiceAvatarColumn">{adaptation.case_sensitive == 0 ? "No" : "Yes"}</div>
                          </TableCell>
                          <TableCell>
                            {moment(adaptation.created_at).format("MM/DD/YYYY")} at {moment(adaptation.created_at).format("hh:mm:ssA")}
                          </TableCell>
                          <TableCell>
                            <button className="btn btn-sm btn-default" onClick={(e) => deleteAdaptation(adaptation.id, true)}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                <Box>
                  <center>
                    <br />
                    <br />
                    No sequences exist for this project. Build some to see them here.
                    <br />
                    <br />
                  </center>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
