import "./VectorVoice.css";
//import "./darkMode.css";
import "./panels.css";
import { BrowserRouter, Route } from "react-router-dom";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Setup from "../Setup/Setup";
import Studio from "../Studio/Studio";
import NewVoice from "../Studio/NewVoice";
import Record from "../Studio/Record";
import Login from "../Login/Login";
import useToken from "./useToken";
import Projects from "../Projects/Projects";
import Project from "../Projects/Project";
import Sequences from "../Sequences/Sequences";
import Sequence from "../Sequences/Sequence";
import Marketplace from "../Marketplace/Marketplace";
import SpeechAdaptations from "../Settings/SpeechAdaptations";
import SpeechAdaptation from "../Settings/SpeechAdaptation";
import { Grid } from "@mui/material";
import DocumentTitle from "react-document-title";
import Tools from "../Tools/Tools";
import Voices from "../Voices/Voices";
import Voice from "../Voices/Voice";
import VoiceFiles from "../Voices/VoiceFiles";

const VectorVoice = () => {
    const { token, setToken } = useToken();
    if (!token) {
        return (
            <BrowserRouter>
                <Login setToken={setToken} />
                <Footer />
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <Grid container spacing={0}>
                    <Grid>
                        <Header setToken={setToken} />
                    </Grid>
                    <Grid item id="mainColumn" style={{ width: "100% !important" }}>
                        <Route path="/setup" component={Setup} />
                        <Route exact path="/studio" component={Studio} />
                        <Route exact path="/studio/new" component={NewVoice} />
                        <Route exact path="/studio/record/:voice_uuid" component={Record} />
                        <Route exact path="/marketplace" component={Marketplace} />
                        <Route exact path="/projects" component={Projects} />
                        <Route exact path="/project/:project_uuid" component={Project} />
                        <Route exact path="/project/:project_uuid/sequences/" component={Sequences} />
                        <Route exact path="/project/:project_uuid/sequence/:sequence_uuid" component={Sequence} />
                        <Route exact path="/settings/speech_adaptations" component={SpeechAdaptations} />
                        <Route exact path="/settings/speech_adaptation/:id" component={SpeechAdaptation} />
                        <Route exact path="/voices" component={Voices} />
                        <Route exact path="/voice/:id" component={Voice} />
                        <Route exact path="/voice/:id/files" component={VoiceFiles} />
                    </Grid>
                </Grid>
                <Footer />
            </BrowserRouter>
        );
    }
};

export default VectorVoice;
