import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Grid, Paper, Typography, Box, MenuItem, Menu, Avatar, Divider, ListItemIcon, MenuList, ListItemText } from "@mui/material";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import metadata from "../../metadata.json";
import AccountMenu from "./AccountMenu";
import MicIcon from "@mui/icons-material/Mic";
import SourceIcon from "@mui/icons-material/Source";
import SettingsIcon from "@mui/icons-material/Settings";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";

function Header({ setToken }) {
    let history = useHistory();
    const pathname = useLocation().pathname;
    console.log(history.location.pathname);
    const [wordTotal, setWordTotal] = useState(null);
    const [remainingWidth, setRemainingWidth] = useState();
    const logout = () => {
        setToken("");
        history.push("/");
    };

    let objUser = JSON.parse(sessionStorage.getItem("user"));
    //    console.log(objUser);

    const handleClick = () => {
        setToken("");
        history.push("/");
    };

    async function getWordCount() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/word_count/" + objUser.user_uuid);
        const data = await response.json();
        let remaining = 1000000 - data[0].ttl_words;
        setWordTotal(remaining);
        let pct = (parseInt(remaining) / 1000000) * 100;
        setRemainingWidth(pct + "%");
    }

    useEffect(() => {
        getWordCount();
    }, []);

    return (
        <>
            <div id="leftnav">
                <img src="/assets/img/speechGPT_LogoC.png" className="headerLogo" alt="logo" />
                <ul className="leftnavLinks">
                    <li className={history.location.pathname === "/projects" || history.location.pathname === "/projects/" ? "leftnavLinkActive" : ""}>
                        <Link to="/projects">
                            <ListItemIcon>
                                <SourceIcon fontSize="small" />
                            </ListItemIcon>
                            Projects
                        </Link>
                    </li>
                    <li className={history.location.pathname === "/settings/speech_adaptations" || history.location.pathname === "/settings/speech_adaptations/" ? " leftnavLinkActive" : ""}>
                        <Link to="/settings/speech_adaptations">
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </Link>
                    </li>
                </ul>

                <Box className="leftBottomBox">
                    <Paper className="progressBox">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="remainingWords">{wordTotal}</div>
                                <div className="infoPill"> of 1,000,000 words</div>
                            </div>
                            <div className="col-sm-12">
                                <div className="progressBar">
                                    <div className="progressBarInner" id="progressBarInner" style={{ width: remainingWidth }}></div>
                                </div>
                            </div>
                            <div className="col-sm-12 smInfo">Renews on 10/01/2022</div>
                        </div>
                    </Paper>
                </Box>

                <div className="versionInfo" style={{ position: "absolute", bottom: "15px", left: "22px" }}>
                    {metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision}.{process.env.REACT_APP_ENVIRONMENT_LABEL}
                </div>
            </div>
            <AccountMenu label={objUser.first_name.substring(0, 1)} first_name={objUser.first_name} last_name={objUser.last_name} logout={logout} />
        </>
    );
}

Header.propTypes = {
    setToken: PropTypes.func.isRequired,
};

export default Header;
