import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ProjectMenu from "../Common/ProjectMenu";

export default function Marketplace() {
    let objUser = JSON.parse(sessionStorage.getItem("user"));

    async function getData() {
        const response = await fetch(process.env.REACT_APP_API_SERVER_URL + "/api/voices");
        const data = await response.json();
        console.log(data);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid item xs={8} id="page-head">
                    <ol className="breadcrumb">
                        <li>Marketplace</li>
                    </ol>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right", padding: "3px 100px 3px 20px" }}></Grid>
            </Grid>
        </>
    );
}
