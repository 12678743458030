import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";

export default function Setup() {
    return (
        <div className="page wizardPage">
            <div id="page-head">
                <div id="page-title">
                    <div className="row">
                        <div className="col-md-9">
                            <ol className="breadcrumb">
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel panel-success panel-bordered wizardPanel">
                <div className="panel-body">
                    <div className="row pageHeader">
                        <div className="col-sm-12">
                            <h4>Setup</h4>
                            Complete the quick form below to get set up with Vector Voice and start recording.
                            <div className="formDivider"></div>
                            <div className="row formRow">
                                <div className="col-sm-6">
                                    <label className="formLabel">First Name</label>
                                    <input type="text" name="first_name" className="form-control"/>
                                </div>
                                <div className="col-sm-6">
                                    <label className="formLabel">Last Name</label>
                                    <input type="text" name="last_name" className="form-control"/>
                                </div>
                            </div>
                            <div className="row formRow">
                                <div className="col-sm-12">
                                    <label className="formLabel">Your Role</label>
                                    <select className="form-control">
                                        <option value="">Select...</option>
                                    </select>
                                </div>
                                </div>
                                <div className="row formRow">
                                <div className="col-sm-12">
                                    <label className="formLabel">Business Type</label>
                                    <select className="form-control">
                                        <option value="">Select...</option>
                                    </select>
                                    <br></br>
                                </div>
                            </div>
                            <div className="row formRow">
                                <div className="col-sm-12">
                                    <a className="btn btn-big btn-block btn-success" href="/studio/new">Continue</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
